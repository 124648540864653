import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import axios from 'axios';

// Animation keyframes
const flipAnimation = keyframes`
  from { transform: rotateY(0deg); }
  to { transform: rotateY(180deg); }
`;

const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

// Color utilities
const getPerformanceColor = (value, type) => {
  switch (type) {
    case 'killRate':
      if (value >= 30) return '#7E57C2';
      if (value >= 15) return '#5C6BC0';
      return '#42A5F5';
    case 'breakRate':
      if (value >= 25) return '#26A69A';
      if (value >= 10) return '#66BB6A';
      return '#9CCC65';
    case 'success':
      if (value >= 70) return '#4CAF50';
      if (value >= 50) return '#FFA726';
      return '#EF5350';
    default:
      return '#BC9C65';
  }
};

const getTrendIndicator = (current, previous) => {
  if (!previous) return null;
  const difference = ((current - previous) / previous) * 100;
  if (difference > 5) return { color: '#4CAF50', symbol: '↑' };
  if (difference < -5) return { color: '#EF5350', symbol: '↓' };
  return { color: '#FFA726', symbol: '→' };
};

// Styled Components
const CardContainer = styled.div`
  width: 320px;
  height: 480px;
  perspective: 1000px;
  margin: 1rem;
  position: relative;

  ${props => props.isSelected && css`
    &::after {
      content: '';
      position: absolute;
      top: -5px;
      left: -5px;
      right: -5px;
      bottom: -5px;
      border: 3px solid #BC9C65;
      border-radius: 12px;
      pointer-events: none;
      animation: ${fadeIn} 0.3s ease-in;
    }
  `}
`;

const Card = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  transform: ${props => props.isFlipped ? 'rotateY(180deg)' : 'rotateY(0)'};
  cursor: pointer;
`;

const CardSide = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background: linear-gradient(145deg, #9A7F5D 0%, #8B7355 100%);
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
`;

const CardBack = styled(CardSide)`
  transform: rotateY(180deg);
  background: linear-gradient(145deg, #8B7355 0%, #9A7F5D 100%);
  padding: 1rem;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background: #BC9C65;
    border-radius: 3px;
  }
`;

const FighterImage = styled.div`
  width: 100%;
  height: 200px;
  background-image: url(${props => `/images/${props.fighterName?.toLowerCase().replace(/\s+/g, '_')}.png`});
  background-size: cover;
  background-position: center;
  border-bottom: 3px solid rgba(0, 0, 0, 0.2);
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100px;
    background: linear-gradient(to top, rgba(0,0,0,0.7) 0%, transparent 100%);
  }
`;

const ContentSection = styled.div`
  padding: 1rem;
`;

const FighterName = styled.h3`
  color: #BC9C65;
  text-align: center;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
`;

const StatGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.8rem;
  margin-top: 1rem;
`;

const StatItem = styled.div`
  background-color: rgba(0, 0, 0, 0.1);
  padding: 0.8rem;
  border-radius: 6px;
  transition: transform 0.2s, background-color 0.2s;
  position: relative;
  overflow: hidden;

  ${props => props.highlighted && css`
    background-color: rgba(188, 156, 101, 0.2);
    transform: scale(1.05);
  `}

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
  }

  ${props => props.achievement && css`
    background: linear-gradient(145deg, #BC9C65 0%, #9A7F5D 100%);
    animation: ${pulseAnimation} 2s infinite;
  `}
`;

const StatLabel = styled.span`
  color: #FFD700;
  font-weight: bold;
  display: block;
  font-size: 0.8rem;
  margin-bottom: 0.3rem;
`;

const StatValue = styled.span`
  color: #BC9C65;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  small {
    font-size: 0.8rem;
    opacity: 0.7;
  }
`;

const TrendIndicator = styled.span`
  color: ${props => props.color};
  font-size: 0.9rem;
  font-weight: bold;
`;

const PerformanceBar = styled.div`
  height: 4px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  margin-top: 0.3rem;
  overflow: hidden;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: ${props => props.percentage}%;
    background-color: ${props => props.color};
    transition: width 0.3s ease;
  }
`;

const CategoryLabel = styled.h4`
  color: #BC9C65;
  margin: 1.5rem 0 1rem;
  font-size: 1rem;
  text-align: center;
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 50px;
    height: 1px;
    background-color: rgba(188, 156, 101, 0.3);
  }

  &::before {
    left: 20px;
  }

  &::after {
    right: 20px;
  }
`;

const ComparisonTooltip = styled.div`
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.9);
  padding: 0.5rem;
  border-radius: 4px;
  font-size: 0.8rem;
  color: white;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s;
  white-space: nowrap;
  z-index: 10;

  ${StatItem}:hover & {
    opacity: 1;
  }
`;

const FlipButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 8px 16px;
  background-color: rgba(188, 156, 101, 0.9);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  z-index: 10;
  transition: all 0.3s ease;
  backdrop-filter: blur(4px);

  &:hover {
    background-color: #BC9C65;
    transform: translateY(-2px);
  }
`;

const AdminControls = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(188, 156, 101, 0.3);
`;

const AdminButton = styled.button`
  background-color: ${props => props.variant === 'delete' ? '#ff6b6b' : '#BC9C65'};
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${props => props.variant === 'delete' ? '#ff5252' : '#9A7F5D'};
  }
`;

// Helper function for calculating success rates
const calculateRate = (success, attempts) => {
  if (!attempts) return 0;
  return (success / attempts) * 100;
};

// Main Component
function FighterCard({ 
  fighter, 
  isAdmin, 
  onDelete, 
  onClick, 
  isSelected, 
  allFighters = [] 
}) {
  const [isFlipped, setIsFlipped] = useState(false);
  const [highlightedStat, setHighlightedStat] = useState(null);

  const calculatePerformanceMetrics = () => {
    const stats = fighter.stats || {};
    const totalMatches = (stats.kills || 0) + (stats.breaks || 0);
    
    return {
      killRate: totalMatches ? ((stats.kills || 0) / totalMatches) * 100 : 0,
      breakRate: totalMatches ? ((stats.breaks || 0) / totalMatches) * 100 : 0,
      takedownRate: calculateRate(stats.takedowns || 0, stats.takedownAttempts || 0),
      passRate: calculateRate(stats.passes || 0, stats.passAttempts || 0),
      sweepRate: calculateRate(stats.sweeps || 0, stats.sweepAttempts || 0),
      averagePoints: totalMatches ? (stats.elbowGeniePoints || 0) / totalMatches : 0
    };
  };

  const getStatComparison = (statName) => {
    const average = getAverageStats(allFighters, statName);
    const value = fighter.stats?.[statName] || 0;
    const difference = ((value - average) / average) * 100;
    return {
      difference,
      percentile: getPercentileRank(value, allFighters.map(f => f.stats?.[statName] || 0))
    };
  };

  const metrics = calculatePerformanceMetrics();

  const handleFlip = (e) => {
    e.stopPropagation();
    setIsFlipped(!isFlipped);
  };

  const handleDelete = async (e) => {
    e.stopPropagation();
    if (window.confirm('Are you sure you want to delete this fighter?')) {
      try {
        await axios.delete(process.env.REACT_APP_API_URL +`/api/fighters/${fighter._id}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        if (onDelete) onDelete(fighter._id);
      } catch (error) {
        console.error('Failed to delete fighter:', error);
        alert('Failed to delete fighter. Please try again.');
      }
    }
  };

  return (
    <CardContainer isSelected={isSelected} onClick={() => onClick?.(fighter)}>
      <Card isFlipped={isFlipped}>
        {/* Front of card */}
        <CardSide>
          <FighterImage fighterName={fighter.name} />
          <FlipButton onClick={handleFlip}>Show Stats</FlipButton>
          <ContentSection>
            <FighterName>{fighter.name}</FighterName>
            
            <StatGrid>
              <StatItem achievement={fighter.stats?.kills >= 5}>
                <StatLabel>Kills</StatLabel>
                <StatValue>{fighter.stats?.kills || 0}</StatValue>
                <PerformanceBar 
                  percentage={metrics.killRate} 
                  color={getPerformanceColor(metrics.killRate, 'killRate')}
                />
              </StatItem>
              <StatItem achievement={fighter.stats?.breaks >= 5}>
                <StatLabel>Breaks</StatLabel>
                <StatValue>{fighter.stats?.breaks || 0}</StatValue>
                <PerformanceBar 
                  percentage={metrics.breakRate} 
                  color={getPerformanceColor(metrics.breakRate, 'breakRate')}
                />
              </StatItem>
              <StatItem>
                <StatLabel>Total Points</StatLabel>
                <StatValue>{fighter.stats?.elbowGeniePoints || 0}</StatValue>
              </StatItem>
              <StatItem achievement={metrics.averagePoints >= 5}>
                <StatLabel>Avg Points</StatLabel>
                <StatValue>{metrics.averagePoints.toFixed(1)}</StatValue>
                <PerformanceBar 
                  percentage={(metrics.averagePoints / 10) * 100}
                  color={getPerformanceColor(metrics.averagePoints * 10, 'success')}
                />
              </StatItem>
            </StatGrid>
          </ContentSection>
        </CardSide>

        {/* Back of card */}
        <CardBack>
          <FlipButton onClick={handleFlip}>Show Front</FlipButton>
          <ContentSection>
            <FighterName>{fighter.name}</FighterName>

            <CategoryLabel>Match Overview</CategoryLabel>
            <StatGrid>
              <StatItem>
                <StatLabel>Total Matches</StatLabel>
                <StatValue>
                  {(fighter.stats?.kills || 0) + (fighter.stats?.breaks || 0)}
                </StatValue>
              </StatItem>
              <StatItem>
                <StatLabel>Win Methods</StatLabel>
                <StatValue>
                  {fighter.stats?.kills || 0}K / {fighter.stats?.breaks || 0}B</StatValue>
              </StatItem>
              <StatItem>
                <StatLabel>Kill Rate</StatLabel>
                <StatValue>{metrics.killRate.toFixed(1)}%</StatValue>
                <PerformanceBar 
                  percentage={metrics.killRate}
                  color={getPerformanceColor(metrics.killRate, 'killRate')}
                />
              </StatItem>
              <StatItem>
                <StatLabel>Break Rate</StatLabel>
                <StatValue>{metrics.breakRate.toFixed(1)}%</StatValue>
                <PerformanceBar 
                  percentage={metrics.breakRate}
                  color={getPerformanceColor(metrics.breakRate, 'breakRate')}
                />
              </StatItem>
            </StatGrid>

            <CategoryLabel>Position Control</CategoryLabel>
            <StatGrid>
              <StatItem>
                <StatLabel>Takedowns</StatLabel>
                <StatValue>
                  {fighter.stats?.takedowns || 0}/{fighter.stats?.takedownAttempts || 0}
                  <small>({metrics.takedownRate.toFixed(1)}%)</small>
                </StatValue>
                <PerformanceBar 
                  percentage={metrics.takedownRate}
                  color={getPerformanceColor(metrics.takedownRate, 'success')}
                />
              </StatItem>
              <StatItem>
                <StatLabel>Passes</StatLabel>
                <StatValue>
                  {fighter.stats?.passes || 0}/{fighter.stats?.passAttempts || 0}
                  <small>({metrics.passRate.toFixed(1)}%)</small>
                </StatValue>
                <PerformanceBar 
                  percentage={metrics.passRate}
                  color={getPerformanceColor(metrics.passRate, 'success')}
                />
              </StatItem>
              <StatItem>
                <StatLabel>Sweeps</StatLabel>
                <StatValue>
                  {fighter.stats?.sweeps || 0}/{fighter.stats?.sweepAttempts || 0}
                  <small>({metrics.sweepRate.toFixed(1)}%)</small>
                </StatValue>
                <PerformanceBar 
                  percentage={metrics.sweepRate}
                  color={getPerformanceColor(metrics.sweepRate, 'success')}
                />
              </StatItem>
              <StatItem>
                <StatLabel>Dominant Positions</StatLabel>
                <StatValue>
                  M:{fighter.stats?.mounts || 0} B:{fighter.stats?.backtakes || 0}
                </StatValue>
              </StatItem>
            </StatGrid>

            <CategoryLabel>Performance History</CategoryLabel>
            <StatGrid>
              <StatItem achievement={fighter.stats?.currentStreak >= 3}>
                <StatLabel>Current Streak</StatLabel>
                <StatValue>{fighter.stats?.currentStreak || 0}</StatValue>
                <ComparisonTooltip>
                  {fighter.stats?.currentStreak ? `Top ${100 - getStatComparison('currentStreak').percentile}%` : 'No active streak'}
                </ComparisonTooltip>
              </StatItem>
              <StatItem achievement={fighter.stats?.longestStreak >= 5}>
                <StatLabel>Longest Streak</StatLabel>
                <StatValue>{fighter.stats?.longestStreak || 0}</StatValue>
              </StatItem>
              <StatItem>
                <StatLabel>Average Time</StatLabel>
                <StatValue>{fighter.stats?.averageTime || '0:00'}</StatValue>
              </StatItem>
              <StatItem>
                <StatLabel>Quickest Win</StatLabel>
                <StatValue>{fighter.stats?.fastestWinTime || '0:00'}</StatValue>
              </StatItem>
            </StatGrid>

            <CategoryLabel>Point Scoring Details</CategoryLabel>
            <StatGrid>
              <StatItem>
                <StatLabel>Base Points</StatLabel>
                <StatValue>
                  {((fighter.stats?.kills || 0) * 6) + ((fighter.stats?.breaks || 0) * 3)}
                  <small>(K:6, B:3)</small>
                </StatValue>
              </StatItem>
              <StatItem>
                <StatLabel>Position Points</StatLabel>
                <StatValue>
                  {((fighter.stats?.takedowns || 0) * 1) + 
                   ((fighter.stats?.mounts || 0) * 2) + 
                   ((fighter.stats?.backtakes || 0) * 2)}
                  <small>(TD:1, M/B:2)</small>
                </StatValue>
              </StatItem>
              <StatItem achievement={fighter.stats?.quickWins >= 3}>
                <StatLabel>Quick Win Bonus</StatLabel>
                <StatValue>{fighter.stats?.quickWins || 0}</StatValue>
              </StatItem>
              <StatItem>
                <StatLabel>Stall Penalties</StatLabel>
                <StatValue style={{color: '#ff6b6b'}}>
                  -{(fighter.stats?.stalls || 0)}
                </StatValue>
              </StatItem>
            </StatGrid>

            {isAdmin && (
              <AdminControls>
                <AdminButton 
                  onClick={(e) => {
                    e.stopPropagation();
                    onClick?.(fighter);
                  }}
                >
                  Edit Fighter
                </AdminButton>
                <AdminButton 
                  variant="delete" 
                  onClick={handleDelete}
                >
                  Delete Fighter
                </AdminButton>
              </AdminControls>
            )}
          </ContentSection>
        </CardBack>
      </Card>
    </CardContainer>
  );
}

// Helper functions
const getAverageStats = (fighters, stat) => {
  const values = fighters.map(f => f.stats?.[stat] || 0);
  return values.reduce((a, b) => a + b, 0) / values.length;
};

const getPercentileRank = (value, allValues) => {
  const sorted = [...allValues].sort((a, b) => a - b);
  const index = sorted.indexOf(value);
  return Math.round((index / (sorted.length - 1)) * 100);
};

export default FighterCard;
