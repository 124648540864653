import React from 'react';
import styled from 'styled-components';
const DraftContainer = styled.div`
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
`;
const MessageContainer = styled.div`
  text-align: center;
  padding: 3rem;
  background-color: rgba(154, 127, 93, 0.1);
  border-radius: 8px;
  margin: 2rem auto;
  max-width: 800px;
`;
const Title = styled.h1`
  color: #BC9C65;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
  letter-spacing: 2px;
`;
const Message = styled.p`
  color: white;
  font-size: 1.5rem;
  line-height: 1.6;
  margin-bottom: 2rem;
`;
const TimeInfo = styled.div`
  color: #FFD700;
  font-size: 1.2rem;
  padding: 1rem;
  border-top: 1px solid rgba(188, 156, 101, 0.3);
  margin-top: 2rem;
`;
function DraftClosed() {
  return (
    <DraftContainer>
      <MessageContainer>
        <Title>Draft Access Closed</Title>
        <Message>
          The draft period has ended for the season.
        </Message>
        <TimeInfo>
          Draft will be live again for our next PGF event. Thank you for your support of the PGF WORLD.
        </TimeInfo>
      </MessageContainer>
    </DraftContainer>
  );
}
export default DraftClosed;
