import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import TeamNameModal from './TeamNameModal';

const DashboardContainer = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding: 1.5rem;
  background-color: rgba(154, 127, 93, 0.1);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const UserInfo = styled.div`
  h2 {
    color: #BC9C65;
    margin-bottom: 0.5rem;
  }
  p {
    color: #FFFFFF;
    margin: 0.5rem 0;
  }
`;

const TeamContainer = styled.div`
  margin-top: 2rem;
  background-color: rgba(154, 127, 93, 0.1);
  padding: 1.5rem;
  border-radius: 8px;

  h3 {
    color: #BC9C65;
    margin-bottom: 1.5rem;
  }
`;

const FighterGrid = styled.div`
  display: flex;
  gap: 1.5rem;
  overflow-x: auto;
  padding-bottom: 1rem;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(154, 127, 93, 0.1);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #BC9C65;
    border-radius: 4px;
  }
`;

const FighterCard = styled.div`
  flex: 0 0 250px;
  background-color: #9A7F5D;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;

  &:hover {
    transform: translateY(-5px);
  }

  h4 {
    color: white;
    margin-bottom: 1rem;
  }

  p {
    color: rgba(255, 255, 255, 0.9);
    margin: 0.5rem 0;
  }
`;

const Button = styled.button`
  background-color: ${props => props.$secondary ? '#1a1a1a' : '#BC9C65'};
  color: ${props => props.$secondary ? '#BC9C65' : 'black'};
  border: ${props => props.$secondary ? '1px solid #BC9C65' : 'none'};
  padding: 0.8rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  margin-left: 1rem;
  transition: all 0.2s;

  &:hover {
    background-color: ${props => props.$secondary ? 'rgba(188, 156, 101, 0.1)' : '#9A7F5D'};
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

const TeamNameSection = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 1rem 0;

  p {
    font-size: 1.1rem;
  }
`;

const StatsSection = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-top: 2rem;
`;

const StatCard = styled.div`
  background-color: rgba(154, 127, 93, 0.2);
  padding: 1rem;
  border-radius: 8px;
  text-align: center;

  h4 {
    color: #BC9C65;
    margin-bottom: 0.5rem;
  }

  p {
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
  }
`;

function Dashboard() {
  const [user, setUser] = useState(null);
  const [fighters, setFighters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showTeamNameModal, setShowTeamNameModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const loadDashboard = async () => {
      try {
        const [userResponse, fightersResponse] = await Promise.all([
          axios.get(process.env.REACT_APP_API_URL + '/api/users/profile', {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
          }),
          axios.get(process.env.REACT_APP_API_URL + '/api/fighters', {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
          })
        ]);

        if (!userResponse.data) {
          throw new Error('No user data received');
        }

        if (!Array.isArray(fightersResponse.data)) {
          throw new Error('Invalid fighters data received');
        }

        setUser(userResponse.data);
        setFighters(fightersResponse.data);
      } catch (err) {
        console.error('Dashboard loading error:', err);
        setError(err.message || 'Failed to load dashboard data');
      } finally {
        setLoading(false);
      }
    };

    loadDashboard();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

  const handleDraft = () => {
    navigate('/draft');
  };

  const handleTeamNameSet = (newTeamName) => {
    setUser(prev => ({ ...prev, teamName: newTeamName }));
  };

  const getFighterDetails = (fighterId) => {
    if (!fighterId) return null;
    if (typeof fighterId === 'object' && fighterId._id) return fighterId;
    return fighters.find(f => f._id === fighterId) || null;
  };

  if (loading) return <DashboardContainer>Loading...</DashboardContainer>;
  if (error) return <DashboardContainer>Error: {error}</DashboardContainer>;
  if (!user) return <DashboardContainer>No user data found</DashboardContainer>;

  const currentTeamFighters = user.currentTeam?.[0]?.fighters || [];

  return (
    <DashboardContainer>
      <Header>
        <UserInfo>
          <h2>Welcome, {user.username}</h2>
          <TeamNameSection>
            <p>Team Name: {user.teamName || 'No team name set'}</p>
            <Button $secondary onClick={() => setShowTeamNameModal(true)}>
              {user.teamName ? 'Update Team Name' : 'Set Team Name'}
            </Button>
          </TeamNameSection>
          <p>Daily Credits: {user.dailyCredits}</p>
        </UserInfo>
        <div>
          <Button onClick={handleDraft}>Draft Team</Button>
          <Button $secondary onClick={handleLogout}>Logout</Button>
        </div>
      </Header>

      <TeamContainer>
        <h3>Your Current Team</h3>
        <FighterGrid>
          {Array.from({ length: 7 }).map((_, index) => {
            const fighterId = currentTeamFighters[index];
            const fighter = getFighterDetails(fighterId);
            
            return (
              <FighterCard key={fighter?._id || `empty-${index}`}>
                {fighter ? (
                  <>
                    <h4>{fighter.name}</h4>
                    <p>Wins: {fighter.stats?.wins || 0}</p>
                    <p>Losses: {fighter.stats?.losses || 0}</p>
                    <p>Breaks: {fighter.stats?.breaks || 0}</p>
                    <p>Kills: {fighter.stats?.kills || 0}</p>
                  </>
                ) : (
                  <>
                    <h4>Empty Slot {index + 1}</h4>
                    <p style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
                      Available
                    </p>
                  </>
                )}
              </FighterCard>
            );
          })}
        </FighterGrid>
        {currentTeamFighters.length === 0 && (
          <p style={{ textAlign: 'center', marginTop: '2rem' }}>
            You haven't drafted a team yet. Click the Draft Team button to get started!
          </p>
        )}
      </TeamContainer>

      <StatsSection>
        <StatCard>
          <h4>Daily Credits</h4>
          <p>{user.dailyCredits}</p>
        </StatCard>
        <StatCard>
          <h4>Team Size</h4>
          <p>{currentTeamFighters.length}/7</p>
        </StatCard>
      </StatsSection>

      {showTeamNameModal && (
        <TeamNameModal
          onClose={() => setShowTeamNameModal(false)}
          onTeamNameSet={handleTeamNameSet}
          currentTeamName={user.teamName}
        />
      )}
    </DashboardContainer>
  );
}

export default Dashboard;
