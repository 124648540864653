import React from 'react';
import { Navigate } from 'react-router-dom';

function RequireAdmin({ children }) {
  const token = localStorage.getItem('token');
  const isAdmin = localStorage.getItem('adminToken');

  if (!token || !isAdmin) {
    return <Navigate to="/admin-login" />;
  }

  return children;
}

export default RequireAdmin;