import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: #1a1a1a;
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  border: 2px solid #9A7F5D;
`;

const Title = styled.h3`
  color: #BC9C65;
  margin-bottom: 1.5rem;
  text-align: center;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.8rem;
  margin-bottom: 1rem;
  background-color: rgba(154, 127, 93, 0.1);
  border: 1px solid #BC9C65;
  border-radius: 4px;
  color: white;
  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
`;

const Button = styled.button`
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.2s;

  ${props => props.primary ? `
    background-color: #BC9C65;
    color: black;
    &:hover {
      background-color: #9A7F5D;
    }
  ` : `
    background-color: transparent;
    color: #BC9C65;
    border: 1px solid #BC9C65;
    &:hover {
      background-color: rgba(188, 156, 101, 0.1);
    }
  `}
`;

const ErrorMessage = styled.div`
  color: #ff6b6b;
  margin-bottom: 1rem;
  font-size: 0.9rem;
`;

function TeamNameModal({ onClose, onTeamNameSet, currentTeamName = '' }) {
  const [teamName, setTeamName] = useState(currentTeamName);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!teamName.trim()) {
      setError('Team name cannot be empty');
      return;
    }

    setIsLoading(true);

    try {
      await axios.post(
        process.env.REACT_APP_API_URL + '/api/users/set-team-name',
        { teamName },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      );

      onTeamNameSet(teamName);
      onClose();
    } catch (error) {
      console.error('Failed to set team name:', error);
      setError(error.response?.data?.message || 'Failed to set team name');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={e => e.stopPropagation()}>
        <Title>{currentTeamName ? 'Update Team Name' : 'Set Team Name'}</Title>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <form onSubmit={handleSubmit}>
          <Input
            type="text"
            placeholder="Enter your team name"
            value={teamName}
            onChange={(e) => setTeamName(e.target.value)}
            maxLength={30}
            disabled={isLoading}
          />
          <ButtonContainer>
            <Button type="button" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" primary disabled={isLoading}>
              {isLoading ? 'Saving...' : 'Save'}
            </Button>
          </ButtonContainer>
        </form>
      </ModalContent>
    </ModalOverlay>
  );
}

export default TeamNameModal;