import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
  withCredentials: true
});

// Request interceptor
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    
    // Log API requests in development
    console.log('API Request:', {
      url: config.url,
      method: config.method,
      headers: config.headers,
      data: config.data
    });
    
    return config;
  },
  (error) => {
    console.error('API Request Error:', error);
    return Promise.reject(error);
  }
);

// Response interceptor
api.interceptors.response.use(
  (response) => {
    // Log API responses in development
    console.log('API Response:', {
      url: response.config.url,
      status: response.status,
      data: response.data
    });
    return response;
  },
  (error) => {
    console.error('API Error:', error.response?.data || error.message);

    if (error.response?.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/';
    }
    return Promise.reject(error);
  }
);

// Auth endpoints
export const login = async (credentials) => {
  try {
    const response = await api.post('/api/auth/login', credentials);
    return response;
  } catch (error) {
    console.error('Login Error:', error);
    throw error;
  }
};

export const register = async (userData) => {
  try {
    const response = await api.post('/api/auth/register', userData);
    return response;
  } catch (error) {
    console.error('Registration Error:', error);
    throw error;
  }
};

export const getCurrentUser = async () => {
  const response = await api.get('/api/users/profile');
  return response.data;
};

// Fighter endpoints
export const getFighters = async () => {
  const response = await api.get('/api/fighters');
  return response.data;
};

export const getFighter = async (id) => {
  const response = await api.get(`/api/fighters/${id}`);
  return response.data;
};

// Draft endpoints
export const draftTeam = async (fighters) => {
  const response = await api.post('/api/draft', { fighters });
  return response.data;
};

// Leaderboard endpoints
export const getDailyLeaderboard = async () => {
  const response = await api.get('/api/leaderboard/daily');
  return response.data;
};

export const getSeasonLeaderboard = async () => {
  const response = await api.get('/api/leaderboard/season');
  return response.data;
};

// Match endpoints
export const getMatchHistory = async () => {
  const response = await api.get('/api/matches/history');
  return response.data;
};

// Tournament endpoints
export const getTournamentStatus = async () => {
  const response = await api.get('/api/tournament/status');
  return response.data;
};

export const createPaymentSession = async () => {
  const response = await api.post('/api/tournament/payment');
  return response.data;
};

// Team endpoints
export const setTeamName = async (teamName) => {
  const response = await api.post('/api/users/set-team-name', { teamName });
  return response.data;
};

// Health check endpoint
export const checkHealth = async () => {
  try {
    const response = await api.get('/health');
    return response.data;
  } catch (error) {
    console.error('Health check failed:', error);
    throw error;
  }
};

export default api;