// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import FighterList from './components/FighterList';
import DraftTeam from './components/DraftTeam';
import AdminPanel from './components/AdminPanel';
import AdminLogin from './components/AdminLogin';
import Navigation from './components/Navigation';
import PrivateRoute from './components/PrivateRoute';
import RequireAdmin from './components/RequireAdmin';
import Leaderboard from './components/Leaderboard';
import FighterStats from './components/FighterStats';
import FantasyCorner from './components/FantasyCorner';
import PrizesPage from './components/PrizesPage'; // Add this import

const AppContainer = styled.div`
  background-color: #000000;
  color: #FFFFFF;
  min-height: 100vh;
`;

const MainContent = styled.div`
  padding-top: 1rem;
`;

const GlobalContainer = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 1rem;
`;

function App() {
  return (
    <Router>
      <AppContainer>
        <Navigation />
        <MainContent>
          <GlobalContainer>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/admin-login" element={<AdminLogin />} />
              
              <Route element={<PrivateRoute />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/fighters" element={<FighterList />} />
                <Route path="/draft" element={<DraftTeam />} />
                <Route path="/leaderboard" element={<Leaderboard />} />
                <Route path="/fighter-stats" element={<FighterStats />} />
                <Route path="/fantasy-corner" element={<FantasyCorner />} />
                <Route path="/prizes" element={<PrizesPage />} /> {/* Add this line */}
                <Route 
                  path="/admin" 
                  element={
                    <RequireAdmin>
                      <AdminPanel />
                    </RequireAdmin>
                  } 
                />
              </Route>
            </Routes>
          </GlobalContainer>
        </MainContent>
      </AppContainer>
    </Router>
  );
}

export default App;