import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import * as XLSX from 'xlsx';

// Container Components
const AdminContainer = styled.div`
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
`;

const Header = styled.div`
  background-color: rgba(154, 127, 93, 0.1);
  padding: 1.5rem;
  border-radius: 8px;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h1`
  color: #BC9C65;
  margin: 0;
`;

const TabContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  border-bottom: 2px solid #9A7F5D;
  padding-bottom: 1rem;
  flex-wrap: wrap;
`;

const Tab = styled.button`
  background-color: ${props => props.$active ? '#BC9C65' : 'transparent'};
  color: ${props => props.$active ? 'black' : '#BC9C65'};
  border: 1px solid #BC9C65;
  padding: 0.8rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: ${props => props.$active ? '#9A7F5D' : 'rgba(188, 156, 101, 0.1)'};
  }
`;

const ExportButton = styled.button`
  background-color: #51cf66;
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.2s;
  margin-left: 1rem;

  &:hover {
    background-color: #37b24d;
  }
`;

// Message Components
const ErrorMessage = styled.div`
  color: #ff6b6b;
  background-color: rgba(255, 107, 107, 0.1);
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  text-align: center;
`;

const SuccessMessage = styled.div`
  color: #51cf66;
  background-color: rgba(81, 207, 102, 0.1);
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  text-align: center;
`;

// Form Components
const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FormSection = styled.div`
  background-color: rgba(154, 127, 93, 0.1);
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;

  h3 {
    color: #BC9C65;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid rgba(188, 156, 101, 0.3);
  }
`;

const FormField = styled.div`
  margin-bottom: 1rem;

  label {
    display: block;
    color: #BC9C65;
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
  }

  input, select, textarea {
    width: 100%;
    padding: 0.5rem;
    background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid #BC9C65;
    border-radius: 4px;
    color: white;
    
    &:focus {
      outline: none;
      border-color: #FFD700;
    }
  }

  textarea {
    min-height: 100px;
    resize: vertical;
  }
`;

// New styled components for match history
const WinnerName = styled.span`
  color: #51cf66;
  font-weight: 500;
`;

const LoserName = styled.span`
  color: #ff6b6b;
  font-weight: 500;
`;

// Match Recording Components
const MatchRecordingSection = styled.div`
  background-color: rgba(154, 127, 93, 0.1);
  padding: 2rem;
  border-radius: 8px;
  margin-top: 2rem;
`;

const MatchStats = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
`;

const FighterSelect = styled.select`
  width: 100%;
  padding: 0.8rem;
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid #BC9C65;
  border-radius: 4px;
  color: white;
  margin-bottom: 1rem;

  option {
    background-color: #1a1a1a;
  }
`;

// Table Components
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: rgba(154, 127, 93, 0.1);
  border-radius: 8px;
  overflow: hidden;
  margin-top: 1rem;
`;

const Th = styled.th`
  text-align: left;
  padding: 1rem;
  background-color: #9A7F5D;
  color: white;
  font-weight: bold;
`;

const Td = styled.td`
  padding: 1rem;
  border-bottom: 1px solid rgba(154, 127, 93, 0.2);
  color: white;
`;

// Modal Components
const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: #1a1a1a;
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  border: 2px solid #BC9C65;
`;

const ActionButton = styled.button.attrs(props => ({
  'data-variant': props.$variant
}))`
  background-color: ${props => {
    switch (props.$variant) {
      case 'delete': return '#ff6b6b';
      case 'edit': return '#4dabf7';
      default: return '#BC9C65';
    }
  }};
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 0.5rem;
  font-size: 0.9rem;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.9;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  min-height: 200px;
  padding: 0.8rem;
  background-color: rgba(0, 0, 0, 0.1);
  border: 1px solid #BC9C65;
  border-radius: 4px;
  color: white;
  resize: vertical;
  
  &:focus {
    outline: none;
    border-color: #FFD700;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 0.8rem;
  background-color: rgba(0, 0, 0, 0.1);
  border: 1px solid #BC9C65;
  border-radius: 4px;
  color: white;
  
  option {
    background-color: #1a1a1a;
  }
`;

const QuickWinNotice = styled.div`
  color: #51cf66;
  font-size: 0.9rem;
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: rgba(81, 207, 102, 0.1);
  border-radius: 4px;
`;

const extractFighterId = (fighterData) => {
  if (!fighterData) return null;
  if (typeof fighterData === 'string') return fighterData;
  if (fighterData && typeof fighterData === 'object' && fighterData._id) return fighterData._id;
  return null;
};

function AdminPanel() {
  // State Management
  const [fighters, setFighters] = useState([]);
  const [users, setUsers] = useState([]);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showPostModal, setShowPostModal] = useState(false);
  const [modalMode, setModalMode] = useState('add');
  const [selectedItem, setSelectedItem] = useState(null);
  const [activeTab, setActiveTab] = useState('fighters');
  const [matchHistory, setMatchHistory] = useState([]);

  
  // Form Data State
  const [formData, setFormData] = useState({
    name: '',
    price: '',
    imageUrl: '',
    bio: '',
    title: '',
    content: '',
    type: 'fight_report',
    relatedMatches: [],
    stats: {
      wins: 0,
      losses: 0,
      draws: 0,
      kills: 0,
      breaks: 0,
      takedowns: 0,
      mounts: 0,
      backtakes: 0,
      quickWins: 0,
      stalls: 0
    }
  });

  // Match Data State
  const [matchData, setMatchData] = useState({
    fighter1: { 
      id: '', 
      stats: {
        takedowns: 0,
        mounts: 0,
        backtakes: 0,
        stalls: 0
      }
    },
    fighter2: { 
      id: '', 
      stats: {
        takedowns: 0,
        mounts: 0,
        backtakes: 0,
        stalls: 0
      }
    },
    winner: '',
    method: '',
    time: '',
  });

  // Load Data Function
  const loadData = useCallback(async () => {
    setLoading(true);
    setError('');
    try {
      // Always load fighters first
      const fightersResponse = await axios.get(process.env.REACT_APP_API_URL + '/api/fighters', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      
      if (fightersResponse.data) {
        setFighters(fightersResponse.data);
      }
  
      if (activeTab === 'users') {
        try {
          console.log('Loading users...');
          const usersResponse = await axios.get(process.env.REACT_APP_API_URL + '/api/admin/users', {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
          });
          console.log('Users loaded:', usersResponse.data);
          setUsers(usersResponse.data);
        } catch (error) {
          console.error('Error loading users:', error);
          setError('Failed to load users: ' + error.message);
        }
      }

      if (activeTab === 'posts') {
        try {
          console.log('Loading blog posts...');
          const postsResponse = await axios.get(process.env.REACT_APP_API_URL + '/api/posts', {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
          });
          console.log('Posts loaded:', postsResponse.data);
          setPosts(postsResponse.data);
        } catch (error) {
          console.error('Error loading posts:', error);
          setError('Failed to load posts: ' + error.message);
        }
      }

      if (activeTab === 'matches' || activeTab === 'history') {
        const matchResponse = await axios.get(process.env.REACT_APP_API_URL + '/api/matches/history', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
  
        if (matchResponse.data) {
          // Filter out any invalid matches
          const validMatches = matchResponse.data.filter(match => 
            match && 
            (match.winner || match.winner?._id) && 
            (match.loser || match.loser?._id)
          );
  
          const processedMatches = validMatches.map(match => ({
            ...match,
            winner: match.winner?._id || match.winner,
            loser: match.loser?._id || match.loser,
            createdAt: match.createdAt,
            winnerStats: match.winnerStats || {},
            loserStats: match.loserStats || {},
            method: match.method || 'unknown',
            time: match.time || 0,
            _id: match._id
          }));
  
          // Sort matches by date
          const sortedMatches = processedMatches.sort((a, b) => 
            new Date(b.createdAt) - new Date(a.createdAt)
          );
  
          setMatchHistory(sortedMatches);
        }
      }
  
      setError('');
    } catch (error) {
      console.error('Failed to load data:', error);
      setError('Failed to load data: ' + (error.response?.data?.message || error.message));
    } finally {
      setLoading(false);
    }
  }, [activeTab]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  // Initial data load
  useEffect(() => {
    loadData();
  }, [loadData]);

  // Helper function to get fighter name
  const getFighterName = useCallback((fighterId) => {
    const fighter = fighters.find(f => f._id === fighterId);
    return fighter ? fighter.name : 'Unknown Fighter';
  }, [fighters]);

  // Excel Export Handler
  const handleExportToExcel = useCallback(() => {
    try {
      const fighterStats = fighters.map(fighter => ({
        Name: fighter.name,
        Price: fighter.price,
        'Win-Loss-Draw': `${fighter.stats?.wins || 0}-${fighter.stats?.losses || 0}-${fighter.stats?.draws || 0}`,
        Kills: fighter.stats?.kills || 0,
        Breaks: fighter.stats?.breaks || 0,
        Takedowns: fighter.stats?.takedowns || 0,
        Mounts: fighter.stats?.mounts || 0,
        'Back Takes': fighter.stats?.backtakes || 0,
        'Quick Wins': fighter.stats?.quickWins || 0,
        Stalls: fighter.stats?.stalls || 0,
        'Win Rate': fighter.stats?.wins && (fighter.stats?.wins + fighter.stats?.losses + fighter.stats?.draws) > 0 
          ? `${((fighter.stats.wins / (fighter.stats.wins + fighter.stats.losses + fighter.stats.draws)) * 100).toFixed(1)}%` 
          : '0%'
      }));

      const matchHistoryData = matchHistory.map(match => ({
        Date: new Date(match.createdAt).toLocaleDateString(),
        Winner: getFighterName(match.winner),
        Loser: getFighterName(match.loser),
        Method: match.method.charAt(0).toUpperCase() + match.method.slice(1),
        'Time (mm:ss)': `${Math.floor(match.time / 60)}:${(match.time % 60).toString().padStart(2, '0')}`,
        Points: match.points,
        'Winner Takedowns': match.winnerStats?.takedowns || 0,
        'Winner Mounts': match.winnerStats?.mounts || 0,
        'Winner Back Takes': match.winnerStats?.backtakes || 0,
        'Winner Stalls': match.winnerStats?.stalls || 0
      }));

      const wb = XLSX.utils.book_new();
      const wsStats = XLSX.utils.json_to_sheet(fighterStats);
      const wsHistory = XLSX.utils.json_to_sheet(matchHistoryData);

      XLSX.utils.book_append_sheet(wb, wsStats, "Fighter Stats");
      XLSX.utils.book_append_sheet(wb, wsHistory, "Match History");

      XLSX.writeFile(wb, `PGF_Stats_${new Date().toISOString().split('T')[0]}.xlsx`);
      setSuccess('Data exported successfully');
    } catch (error) {
      console.error('Export error:', error);
      setError('Failed to export data: ' + error.message);
    }
  }, [fighters, matchHistory, getFighterName]);

  // Match Data Handlers
  const handleMatchDataChange = (fighter, field, value) => {
    setMatchData(prev => ({
      ...prev,
      [fighter]: {
        ...prev[fighter],
        stats: {
          ...prev[fighter].stats,
          [field]: typeof value === 'boolean' ? value : (parseInt(value) || 0)
        }
      }
    }));
  };

  // Match Submission Handler
  const handleMatchSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    // Validate required fields
    if (!matchData.fighter1.id || !matchData.fighter2.id || !matchData.winner || !matchData.method || !matchData.time) {
      setError('Please fill in all required fields');
      return;
    }

    try {
      const winnerStats = matchData.fighter1.id === matchData.winner 
        ? matchData.fighter1.stats 
        : matchData.fighter2.stats;

      const loserStats = matchData.fighter1.id === matchData.winner 
        ? matchData.fighter2.stats 
        : matchData.fighter1.stats;

      const timeInSeconds = parseInt(matchData.time);

      const payload = {
        winnerId: matchData.winner,
        loserId: matchData.fighter1.id === matchData.winner 
          ? matchData.fighter2.id 
          : matchData.fighter1.id,
        method: matchData.method,
        time: timeInSeconds,
        winnerStats: {
          takedowns: winnerStats.takedowns || 0,
          mounts: winnerStats.mounts || 0,
          backtakes: winnerStats.backtakes || 0,
          stalls: winnerStats.stalls || 0,
          breaks: matchData.method === 'break' ? 1 : 0,
          kills: matchData.method === 'kill' ? 1 : 0
        },
        loserStats: {
          takedowns: loserStats.takedowns || 0,
          mounts: loserStats.mounts || 0,
          backtakes: loserStats.backtakes || 0,
          stalls: loserStats.stalls || 0
        }
      };

      const response = await axios.post(
        process.env.REACT_APP_API_URL + '/api/matches/result', 
        payload,
        {
          headers: { 
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          }
        }
      );

      console.log('Match recorded:', response.data);
      setSuccess('Match recorded successfully');

      // Reset match form
      setMatchData({
        fighter1: { id: '', stats: {
          takedowns: 0,
          mounts: 0,
          backtakes: 0,
          stalls: 0
        }},
        fighter2: { id: '', stats: {
          takedowns: 0,
          mounts: 0,
          backtakes: 0,
          stalls: 0
        }},
        winner: '',
        method: '',
        time: '',
      });

      await loadData();

    } catch (error) {
      console.error('Match recording error:', error);
      const errorMessage = error.response?.data?.message || error.message;
      setError(`Failed to record match: ${errorMessage}`);
    }
  };

  // Form Handlers
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setFormData(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleStatsChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      stats: {
        ...prev.stats,
        [field]: parseInt(value) || 0
      }
    }));
  };

  // Post Handlers
  const handleEditPost = (post) => {
    setSelectedItem(post);
    setFormData({
      title: post.title,
      content: post.content,
      type: post.type,
      relatedMatches: post.relatedMatches || []
    });
    setModalMode('edit');
    setShowPostModal(true);
  };

  const handleDeletePost = async (postId) => {
    if (!window.confirm('Are you sure you want to delete this post?')) return;
    
    try {
      setError('');
      await axios.delete(`process.env.REACT_APP_API_URL/api/posts/${postId}`,
         {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setSuccess('Post deleted successfully');
      loadData(); // Reload the posts
    } catch (error) {
      console.error('Delete error:', error);
      setError('Failed to delete post: ' + (error.response?.data?.message || error.message));
    }
  };

  const handlePostSubmit = async (e) => {
  e.preventDefault();
  setError('');
  setSuccess('');

  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Authentication required');
    }

    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };

    if (modalMode === 'add') {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/posts`, 
        formData,
        { headers }
      );
      setSuccess('Post created successfully');
    } else {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/posts/${selectedItem._id}`,
        formData,
        { headers }
      );
      setSuccess('Post updated successfully');
    }

    setShowPostModal(false);
    resetPostForm();
    await loadData();
  } catch (error) {
    console.error('Submit error:', error);
    setError('Failed to save post: ' + (error.response?.data?.message || error.message));
  }
};

  const resetPostForm = () => {
    setFormData({
      title: '',
      content: '',
      type: 'fight_report',
      relatedMatches: []
    });
  };

  const handleUserSubmit = async (e) => {
  e.preventDefault();
  setError('');
  setSuccess('');

  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Authentication required');
    }

    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };

    if (modalMode === 'add') {
      // Creating new user
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/users`, 
        formData,
        { headers }
      );
      setSuccess('User added successfully');
    } else {
      // Updating existing user
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/admin/users/${selectedItem._id}`,
        formData,
        { headers }
      );
      setSuccess('User updated successfully');
    }

    setShowModal(false);
    await loadData();
    resetForm();
  } catch (error) {
    console.error('User save error:', error);
    const errorMsg = error.response?.data?.message || error.message;
    setError(`Failed to ${modalMode === 'add' ? 'create' : 'update'} user: ${errorMsg}`);
  }
};

  // General Form Submit Handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      if (modalMode === 'add') {
        await axios.post(`process.env.REACT_APP_API_URL/api/${activeTab}`, formData, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        setSuccess(`${activeTab === 'fighters' ? 'Fighter' : 'User'} added successfully`);
      } else {
        await axios.put(`process.env.REACT_APP_API_URL/api/${activeTab}/${selectedItem._id}`, formData, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        setSuccess(`${activeTab === 'fighters' ? 'Fighter' : 'User'} updated successfully`);
      }

      setShowModal(false);
      await loadData();
      resetForm();
    } catch (error) {
      console.error('Submit error:', error);
      setError('Failed to save changes: ' + (error.response?.data?.message || error.message));
    }
  };

  // Delete handler
  const handleDelete = async (id) => {
  if (!window.confirm('Are you sure you want to delete this item?')) return;
  
  try {
    setError('');
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/${activeTab}/${id}`, {
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      }
    });

    if (response.status === 200) {
      setSuccess(`${activeTab === 'fighters' ? 'Fighter' : 'User'} deleted successfully`);
      await loadData(); // Reload the data
    } else {
      throw new Error('Failed to delete');
    }
  } catch (error) {
    console.error('Delete error:', error);
    setError('Failed to delete: ' + (error.response?.data?.message || error.message));
  }
};

  const resetForm = () => {
    if (activeTab === 'users') {
      setFormData({
        username: '',
        email: '',
        password: '',
        teamName: '',
        role: 'user',
        dailyCredits: 140
      });
    } else {
      setFormData({
        name: '',
        price: '',
        imageUrl: '',
        bio: '',
        stats: {
          wins: 0,
          losses: 0,
          draws: 0,
          kills: 0,
          breaks: 0,
          takedowns: 0,
          mounts: 0,
          backtakes: 0,
          quickWins: 0,
          stalls: 0
        }
      });
    }
  };

  return (
    <AdminContainer>
      <Header>
        <Title>Admin Panel</Title>
        {activeTab === 'fighters' && (
          <ExportButton onClick={handleExportToExcel}>
            Export to Excel
          </ExportButton>
        )}
      </Header>

      {error && <ErrorMessage>{error}</ErrorMessage>}
      {success && <SuccessMessage>{success}</SuccessMessage>}
      
      <TabContainer>
  <Tab 
    $active={activeTab === 'fighters'} 
    onClick={() => setActiveTab('fighters')}
  >
    Fighters
  </Tab>
  <Tab 
    $active={activeTab === 'users'} 
    onClick={() => setActiveTab('users')}
  >
    Users
  </Tab>
  <Tab 
    $active={activeTab === 'matches'} 
    onClick={() => setActiveTab('matches')}
  >
    Record Matches
  </Tab>
  <Tab 
    $active={activeTab === 'history'} 
    onClick={() => setActiveTab('history')}
  >
    Match History
  </Tab>
  <Tab 
    $active={activeTab === 'posts'} 
    onClick={() => setActiveTab('posts')}
  >
    Blog Posts
  </Tab>
</TabContainer>

      {activeTab !== 'matches' && activeTab !== 'history' && activeTab !== 'posts' && (
        <ActionButton 
          onClick={() => {
            setModalMode('add');
            resetForm();
            setShowModal(true);
          }}
        >
          Add {activeTab === 'fighters' ? 'Fighter' : 'User'}
        </ActionButton>
      )}

      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          {/* Users and Fighters Tables */}
          {(activeTab === 'fighters' || activeTab === 'users') && (
            <Table>
              <thead>
                <tr>
                  <Th>Name</Th>
                  {activeTab === 'fighters' && (
                    <>
                      <Th>Price</Th>
                      <Th>Record</Th>
                      <Th>Stats</Th>
                    </>
                  )}
                  {activeTab === 'users' && (
                    <>
                      <Th>Team Name</Th>
                      <Th>Email</Th>
                      <Th>Role</Th>
                    </>
                  )}
                  <Th>Actions</Th>
                </tr>
              </thead>
              <tbody>
                {(activeTab === 'fighters' ? fighters : users).map(item => (
                  <tr key={item._id}>
                    <Td>{activeTab === 'fighters' ? item.name : item.username}</Td>
                    {activeTab === 'fighters' && (
                      <>
                        <Td>{item.price}</Td>
                        <Td>
                          {item.stats?.wins || 0}-{item.stats?.losses || 0}-{item.stats?.draws || 0}
                        </Td>
                        <Td>
                          K: {item.stats?.kills || 0} | B: {item.stats?.breaks || 0}
                        </Td>
                      </>
                    )}
                    {activeTab === 'users' && (
                      <>
                        <Td style={{ color: '#BC9C65' }}>{item.teamName || 'Unnamed Team'}</Td>
                        <Td>{item.email}</Td>
                        <Td>{item.role}</Td>
                      </>
                    )}
                    <Td>
                      <ActionButton 
                        $variant="edit"
                        onClick={() => {
                          setSelectedItem(item);
                          setFormData(item);
                          setModalMode('edit');
                          setShowModal(true);
                        }}
                      >
                        Edit
                      </ActionButton>
                      <ActionButton 
                        $variant="delete"
                        onClick={() => handleDelete(item._id)}
                      >
                        Delete
                      </ActionButton>
                    </Td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}

          {/* Match History Table */}
          {activeTab === 'history' && (
  <div>
    <h2>Match History</h2>
    <Table>
      <thead>
        <tr>
          <Th>Date</Th>
          <Th>Winner</Th>
          <Th>Loser</Th>
          <Th>Method</Th>
          <Th>Time</Th>
          <Th>Details</Th>
        </tr>
      </thead>
      <tbody>
        {matchHistory && matchHistory.length > 0 ? matchHistory.map(match => {
          if (!match) return null; // Skip if match is null/undefined
          
          // Safely extract IDs with null checks
          const winnerId = match.winner ? 
            (typeof match.winner === 'object' ? match.winner._id : match.winner) : 
            null;
            
          const loserId = match.loser ? 
            (typeof match.loser === 'object' ? match.loser._id : match.loser) : 
            null;
          
          // Only render if we have valid IDs
          if (!winnerId || !loserId) return null;

          const winner = fighters.find(f => f._id === winnerId);
          const loser = fighters.find(f => f._id === loserId);

          return (
            <tr key={match._id || Math.random()}>
              <Td>{match.createdAt ? new Date(match.createdAt).toLocaleDateString() : 'N/A'}</Td>
              <Td>
                <WinnerName>
                  {winner ? winner.name : `Unknown Fighter (${winnerId})`}
                </WinnerName>
              </Td>
              <Td>
                <LoserName>
                  {loser ? loser.name : `Unknown Fighter (${loserId})`}
                </LoserName>
              </Td>
              <Td>
                {match.method ? 
                  `${match.method.charAt(0).toUpperCase() + match.method.slice(1)}
                   ${match.points ? `(${match.points} pts)` : ''}`
                  : 'N/A'
                }
              </Td>
              <Td>
                {match.time ? 
                  `${Math.floor(match.time / 60)}:${(match.time % 60).toString().padStart(2, '0')}
                   ${match.time <= 60 ? ' ⚡' : ''}`
                  : 'N/A'
                }
              </Td>
              <Td>
                TD: {match.winnerStats?.takedowns || 0}, 
                M: {match.winnerStats?.mounts || 0}, 
                BT: {match.winnerStats?.backtakes || 0},
                S: {match.winnerStats?.stalls || 0}
              </Td>
            </tr>
          );
        }) : (
          <tr>
            <Td colSpan="6" style={{ textAlign: 'center' }}>No match history available</Td>
          </tr>
        )}
      </tbody>
    </Table>
  </div>
)}

          {/* Match Recording Form */}
          {activeTab === 'matches' && (
            <MatchRecordingSection>
              <h2>Record Match</h2>
              <Form onSubmit={handleMatchSubmit}>
                <div>
                  <h3>Fighter 1</h3>
                  <FighterSelect
                    value={matchData.fighter1.id}
                    onChange={(e) => setMatchData({
                      ...matchData,
                      fighter1: { ...matchData.fighter1, id: e.target.value }
                    })}
                    required
                  >
                    <option value="">Select Fighter</option>
                    {fighters.map(fighter => (
                      <option key={fighter._id} value={fighter._id}>
                        {fighter.name}
                      </option>
                    ))}
                  </FighterSelect>

                  <MatchStats>
                    <FormField>
                      <label>Takedowns</label>
                      <input
                        type="number"
                        value={matchData.fighter1.stats.takedowns || 0}
                        onChange={(e) => handleMatchDataChange('fighter1', 'takedowns', e.target.value)}
                        min="0"
                      />
                    </FormField>
                    <FormField>
                      <label>Mounts</label>
                      <input
                        type="number"
                        value={matchData.fighter1.stats.mounts || 0}
                        onChange={(e) => handleMatchDataChange('fighter1', 'mounts', e.target.value)}
                        min="0"
                      />
                    </FormField>
                    <FormField>
                      <label>Back Takes</label>
                      <input
                        type="number"
                        value={matchData.fighter1.stats.backtakes || 0}
                        onChange={(e) => handleMatchDataChange('fighter1', 'backtakes', e.target.value)}
                        min="0"
                      />
                    </FormField>
                    <FormField>
                      <label>Stalls</label>
                      <input
                        type="number"
                        value={matchData.fighter1.stats.stalls || 0}
                        onChange={(e) => handleMatchDataChange('fighter1', 'stalls', e.target.value)}
                        min="0"
                      />
                    </FormField>
                  </MatchStats>
                </div>

                <div>
                  <h3>Fighter 2</h3>
                  <FighterSelect
                    value={matchData.fighter2.id}
                    onChange={(e) => setMatchData({
                      ...matchData,
                      fighter2: { ...matchData.fighter2, id: e.target.value }
                    })}
                    required
                  >
                    <option value="">Select Fighter</option>
                    {fighters
                      .filter(f => f._id !== matchData.fighter1.id)
                      .map(fighter => (
                        <option key={fighter._id} value={fighter._id}>
                          {fighter.name}
                        </option>
                      ))}
                  </FighterSelect>

                  <MatchStats>
                    <FormField>
                      <label>Takedowns</label>
                      <input
                        type="number"
                        value={matchData.fighter2.stats.takedowns || 0}
                        onChange={(e) => handleMatchDataChange('fighter2', 'takedowns', e.target.value)}
                        min="0"
                      />
                    </FormField>
                    <FormField>
                      <label>Mounts</label>
                      <input
                        type="number"
                        value={matchData.fighter2.stats.mounts || 0}
                        onChange={(e) => handleMatchDataChange('fighter2', 'mounts', e.target.value)}
                        min="0"
                      />
                    </FormField>
                    <FormField>
                      <label>Back Takes</label>
                      <input
                        type="number"
                        value={matchData.fighter2.stats.backtakes || 0}
                        onChange={(e) => handleMatchDataChange('fighter2', 'backtakes', e.target.value)}
                        min="0"
                      />
                    </FormField>
                    <FormField>
                      <label>Stalls</label>
                      <input
                        type="number"
                        value={matchData.fighter2.stats.stalls || 0}
                        onChange={(e) => handleMatchDataChange('fighter2', 'stalls', e.target.value)}
                        min="0"
                      />
                    </FormField>
                  </MatchStats>
                </div>

                <FormSection style={{ gridColumn: '1 / -1' }}>
                  <h3>Match Details</h3>
                  <MatchStats>
                    <FormField>
                      <label>Winner</label>
                      <select
                        value={matchData.winner}
                        onChange={(e) => setMatchData({ ...matchData, winner: e.target.value })}
                      >
                        <option value="">Select Winner</option>
                        {matchData.fighter1.id && (
                          <option value={matchData.fighter1.id}>
                            {getFighterName(matchData.fighter1.id)}
                          </option>
                        )}
                        {matchData.fighter2.id && (
                          <option value={matchData.fighter2.id}>
                            {getFighterName(matchData.fighter2.id)}
                          </option>
                        )}
                        {'672abc59484c52bcc518777b' && (
                          <option value={'672abc59484c52bcc518777b'}>
                            {getFighterName('672abc59484c52bcc518777b')}
                          </option>
                        )}
                      </select>
                    </FormField>

                    <FormField>
                      <label>Victory Method</label>
                      <select
                        value={matchData.method}
                        onChange={(e) => setMatchData({ ...matchData, method: e.target.value })}
                        required
                      >
                        <option value="">Select Method</option>
                        <option value="draw">Draw</option>
                        <option value="kill">Kill</option>
                        <option value="break">Break</option>
                        <option value="dq">DQ</option>
                      </select>
                    </FormField>

                    <FormField>
                      <label>Time (seconds)</label>
                      <input
                        type="number"
                        value={matchData.time}
                        onChange={(e) => setMatchData({ ...matchData, time: e.target.value })}
                        required
                        min="0"
                      />
                    </FormField>
                  </MatchStats>

                  {parseInt(matchData.time) <= 60 && matchData.time !== '' && (
                    <QuickWinNotice>
                      Quick win bonus will be applied (+1 point)
                    </QuickWinNotice>
                  )}
                </FormSection>

                <ActionButton type="submit" style={{ gridColumn: '1 / -1' }}>
                  Record Match
                </ActionButton>
              </Form>
            </MatchRecordingSection>
          )}

{activeTab === 'posts' && (
  <>
    <ActionButton 
      onClick={() => {
        setModalMode('add');
        setFormData({
          title: '',
          content: '',
          type: 'fight_report',
          relatedMatches: []
        });
        setShowPostModal(true);
      }}
    >
      Create New Post
    </ActionButton>
    
    {loading ? (
      <div>Loading posts...</div>
    ) : (
      <Table>
        <thead>
          <tr>
            <Th>Title</Th>
            <Th>Type</Th>
            <Th>Created</Th>
            <Th>Actions</Th>
          </tr>
        </thead>
        <tbody>
          {posts && posts.length > 0 ? (
            posts.map(post => (
              <tr key={post._id}>
                <Td>{post.title}</Td>
                <Td>{post.type.replace('_', ' ').toUpperCase()}</Td>
                <Td>{new Date(post.createdAt).toLocaleDateString()}</Td>
                <Td>
                  <ActionButton 
                    $variant="edit"
                    onClick={() => handleEditPost(post)}
                  >
                    Edit
                  </ActionButton>
                  <ActionButton 
                    $variant="delete"
                    onClick={() => handleDeletePost(post._id)}
                  >
                    Delete
                  </ActionButton>
                </Td>
              </tr>
            ))
          ) : (
            <tr>
              <Td colSpan="4" style={{ textAlign: 'center' }}>
                No posts found
              </Td>
            </tr>
          )}
        </tbody>
      </Table>
    )}
  </>
)}

          {/* Modals */}
          {showModal && (
  <Modal onClick={() => setShowModal(false)}>
    <ModalContent onClick={e => e.stopPropagation()}>
      <h2>
        {modalMode === 'add' ? 'Add' : 'Edit'} {activeTab === 'fighters' ? 'Fighter' : 'User'}
      </h2>

      <Form onSubmit={activeTab === 'users' ? handleUserSubmit : handleSubmit}>
        {activeTab === 'fighters' ? (
          <>
            <FormSection>
              <h3>Basic Information</h3>
              <FormField>
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name || ''}
                  onChange={handleFormChange}
                  required
                />
              </FormField>
              <FormField>
                <label>Price</label>
                <input
                  type="number"
                  name="price"
                  value={formData.price || ''}
                  onChange={handleFormChange}
                  required
                />
              </FormField>
            </FormSection>

            <FormSection>
              <h3>Fighter Stats</h3>
              {Object.entries(formData.stats).map(([stat, value]) => (
                <FormField key={stat}>
                  <label>
                    {stat.charAt(0).toUpperCase() + stat.slice(1).replace(/([A-Z])/g, ' $1')}
                  </label>
                  <input
                    type="number"
                    value={value}
                    onChange={(e) => handleStatsChange(stat, e.target.value)}
                    min="0"
                  />
                </FormField>
              ))}
            </FormSection>
          </>
        ) : (
          <FormSection>
            <h3>User Information</h3>
            <FormField>
              <label>Username</label>
              <input
                type="text"
                name="username"
                value={formData.username || ''}
                onChange={handleFormChange}
                required
              />
            </FormField>
            <FormField>
              <label>Email</label>
              <input
                type="email"
                name="email"
                value={formData.email || ''}
                onChange={handleFormChange}
                required
              />
            </FormField>
            <FormField>
              <label>Team Name</label>
              <input
                type="text"
                name="teamName"
                value={formData.teamName || ''}
                onChange={handleFormChange}
              />
            </FormField>
            <FormField>
              <label>Role</label>
              <select
                name="role"
                value={formData.role || 'user'}
                onChange={handleFormChange}
              >
                <option value="user">User</option>
                <option value="admin">Admin</option>
              </select>
            </FormField>
            {modalMode === 'add' && (
              <FormField>
                <label>Password</label>
                <input
                  type="password"
                  name="password"
                  value={formData.password || ''}
                  onChange={handleFormChange}
                  required={modalMode === 'add'}
                />
              </FormField>
            )}
          </FormSection>
        )}

        <div style={{ gridColumn: '1 / -1', display: 'flex', gap: '1rem', justifyContent: 'flex-end' }}>
          <ActionButton type="button" onClick={() => setShowModal(false)}>
            Cancel
          </ActionButton>
          <ActionButton type="submit">
            {modalMode === 'add' ? 'Add' : 'Update'} {activeTab === 'fighters' ? 'Fighter' : 'User'}
          </ActionButton>
        </div>
      </Form>
    </ModalContent>
  </Modal>
)}

          {/* Blog Post Modal */}
          {showPostModal && (
            <Modal onClick={() => setShowPostModal(false)}>
              <ModalContent onClick={e => e.stopPropagation()}>
                <h2>{modalMode === 'add' ? 'Create New Post' : 'Edit Post'}</h2>
                
                <Form onSubmit={handlePostSubmit}>
                  <FormSection>
                    <FormField>
                      <label>Title</label>
                      <input
                        type="text"
                        name="title"
                        value={formData.title || ''}
                        onChange={handleFormChange}
                        required
                      />
                    </FormField>

                    <FormField>
                      <label>Type</label>
                      <select
                        name="type"
                        value={formData.type || 'fight_report'}
                        onChange={handleFormChange}
                        required
                      >
                        <option value="fight_report">Fight Report</option>
                        <option value="daily_writeup">Daily Writeup</option>
                        <option value="news">News</option>
                      </select>
                    </FormField>

                    <FormField>
                      <label>Content</label>
                      <TextArea
                        name="content"
                        value={formData.content || ''}
                        onChange={handleFormChange}
                        required
                      />
                    </FormField>

                    {formData.type === 'fight_report' && (
                      <FormField>
                        <label>Related Matches</label>
                        <Select
                          multiple
                          value={formData.relatedMatches || []}
                          onChange={(e) => {
                            const selectedMatches = Array.from(e.target.selectedOptions, option => option.value);
                            setFormData(prev => ({
                              ...prev,
                              relatedMatches: selectedMatches
                            }));
                          }}
                        >
                          {matchHistory.map(match => (
                            <option key={match._id} value={match._id}>
                              {getFighterName(match.winner)} vs {getFighterName(match.loser)} - {match.method}
                            </option>
                          ))}
                        </Select>
                        <small style={{ color: '#BC9C65', marginTop: '0.5rem', display: 'block' }}>
                          Hold Ctrl/Cmd to select multiple matches
                        </small>
                      </FormField>
                    )}
                  </FormSection>

                  <div style={{ display: 'flex', gap: '1rem', justifyContent: 'flex-end' }}>
                    <ActionButton type="button" onClick={() => setShowPostModal(false)}>
                      Cancel
                    </ActionButton>
                    <ActionButton type="submit">
                      {modalMode === 'add' ? 'Create Post' : 'Update Post'}
                    </ActionButton>
                  </div>
                </Form>
              </ModalContent>
            </Modal>
          )}
        </>
      )}
    </AdminContainer>
  );
}

export default AdminPanel;
