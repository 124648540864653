import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import styled from 'styled-components';
import api from '../utils/api';

const RegisterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  max-width: 400px;
  margin: 2rem auto;
  background-color: rgba(154, 127, 93, 0.1);
  border-radius: 8px;
`;

const RegisterForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
`;

const Input = styled.input`
  padding: 0.8rem;
  border: 1px solid #BC9C65;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  &::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
`;

const Button = styled.button`
  background-color: #BC9C65;
  color: #000000;
  border: none;
  padding: 0.8rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.2s;

  &:hover {
    background-color: #9A7F5D;
  }
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

const LoginLink = styled(Link)`
  color: #BC9C65;
  text-decoration: none;
  margin-top: 1rem;
  text-align: center;
  
  &:hover {
    text-decoration: underline;
  }
`;

const ErrorMessage = styled.div`
  color: #ff6b6b;
  margin-bottom: 1rem;
  text-align: center;
  white-space: pre-wrap;
  padding: 1rem;
  background-color: rgba(255, 107, 107, 0.1);
  border-radius: 4px;
`;

const SuccessMessage = styled.div`
  color: #51cf66;
  margin-bottom: 1rem;
  text-align: center;
  white-space: pre-wrap;
  padding: 1rem;
  background-color: rgba(81, 207, 102, 0.1);
  border-radius: 4px;
`;

const Title = styled.h2`
  color: #BC9C65;
  margin-bottom: 1.5rem;
`;

const InfoText = styled.p`
  color: white;
  text-align: center;
  margin-bottom: 1.5rem;
  line-height: 1.5;
`;

const PasswordRequirements = styled.ul`
  color: #BC9C65;
  font-size: 0.9rem;
  margin: 0.5rem 0;
  padding-left: 1.2rem;
`;

function Register() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const validateForm = () => {
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return false;
    }
    if (password.length < 6) {
      setError('Password must be at least 6 characters long');
      return false;
    }
    if (username.length < 3) {
      setError('Username must be at least 3 characters long');
      return false;
    }
    if (!email.includes('@')) {
      setError('Please enter a valid email address');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setIsLoading(true);

    if (!validateForm()) {
      setIsLoading(false);
      return;
    }

    try {
      console.log('Attempting registration with:', { username, email });
      
      const response = await api.post('/api/auth/register', {
        username,
        email,
        password
      });

      console.log('Registration response:', response);

      if (response.data && response.data.token) {
        localStorage.setItem('token', response.data.token);
        setSuccess('Registration successful! Redirecting to dashboard...');
        setTimeout(() => {
          navigate('/dashboard');
        }, 1500);
      } else {
        throw new Error('Registration successful but no token received');
      }
    } catch (error) {
      console.error('Registration error:', error);
      setError(
        error.response?.data?.message || 
        error.message || 
        'Unable to connect to the server. Please try again.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <RegisterContainer>
      <Title>Create Your Account</Title>
      <InfoText>
        Join PGF World and start building your dream team of fighters.
      </InfoText>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {success && <SuccessMessage>{success}</SuccessMessage>}
      <RegisterForm onSubmit={handleSubmit}>
        <Input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
          disabled={isLoading}
          minLength={3}
        />
        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          disabled={isLoading}
        />
        <Input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          disabled={isLoading}
          minLength={6}
        />
        <PasswordRequirements>
          <li>At least 6 characters long</li>
        </PasswordRequirements>
        <Input
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
          disabled={isLoading}
        />
        <Button type="submit" disabled={isLoading}>
          {isLoading ? 'Creating Account...' : 'Create Account'}
        </Button>
      </RegisterForm>
      <LoginLink to="/">
        Already have an account? Login here
      </LoginLink>
    </RegisterContainer>
  );
}

export default Register;