import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';

const Nav = styled.div`
  background-color: #000000;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #9A7F5D;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
`;

const Logo = styled.img`
  height: 40px;
`;

const LogoText = styled.span`
  color: #BC9C65;
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const StyledLink = styled(Link)`
  color: #BC9C65;
  text-decoration: none;
  padding: 0.8rem 1.2rem;
  border-radius: 4px;
  transition: all 0.2s ease;
  font-weight: 500;
  border: 1px solid transparent;

  &:hover {
    border-color: #BC9C65;
    transform: translateY(-2px);
  }

  &.active {
    background-color: #BC9C65;
    color: black;
  }
`;

const AdminButton = styled(StyledLink)`
  background-color: #8B7355;
  font-weight: bold;
  border: 1px solid rgba(255, 255, 255, 0.1);

  &:hover {
    background-color: #9A7F5D;
    border-color: rgba(255, 255, 255, 0.2);
  }
`;

const LogoutButton = styled(StyledLink)`
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid #BC9C65;
  
  &:hover {
    background-color: rgba(188, 156, 101, 0.1);
  }
`;

const AdminLoginLink = styled(StyledLink)`
  background-color: #8B7355;
  color: white;
  font-weight: bold;
  border: 1px solid rgba(255, 255, 255, 0.1);
  
  &:hover {
    background-color: #9A7F5D;
    border-color: rgba(255, 255, 255, 0.2);
  }
`;

function Navigation() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const checkAuth = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + '/api/users/profile', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setUser(response.data);
      } catch (error) {
        console.error('Auth check failed:', error);
        localStorage.removeItem('token');
        localStorage.removeItem('adminToken');
        setUser(null);
      }
    } else {
      setUser(null);
    }
    setLoading(false);
  };

  useEffect(() => {
    checkAuth();
  }, [location.pathname]);

  const handleLogoClick = (e) => {
    e.preventDefault();
    if (user) {
      navigate('/dashboard');
    } else {
      navigate('/');
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('adminToken');
    setUser(null);
    navigate('/');
  };

  if (loading) {
    return <Nav><LogoText>PGF WORLD</LogoText></Nav>;
  }

  return (
    <Nav>
      <LogoContainer onClick={handleLogoClick}>
        <Logo src="/logo192.png" alt="PGF World" />
        <LogoText>PGF WORLD</LogoText>
      </LogoContainer>

      <NavLinks>
        {user ? (
          <>
          <StyledLink to="/fantasy-corner">Fantasy Corner</StyledLink>
          <StyledLink to="/prizes" className={location.pathname === '/prizes' ? 'active' : ''}>
  Prizes
</StyledLink>
            <StyledLink to="/dashboard" className={location.pathname === '/dashboard' ? 'active' : ''}>
              Dashboard
            </StyledLink>
            <StyledLink to="/fighter-stats">Fighter Stats</StyledLink>
            <StyledLink to="/draft" className={location.pathname === '/draft' ? 'active' : ''}>
              Draft Team
            </StyledLink>
            <StyledLink to="/leaderboard" className={location.pathname === '/leaderboard' ? 'active' : ''}>
              Leaderboard
            </StyledLink>
            {user.role === 'admin' && (
              <AdminButton to="/admin" className={location.pathname === '/admin' ? 'active' : ''}>
                Admin Panel
              </AdminButton>
            )}
            <LogoutButton as="button" onClick={handleLogout}>
              Logout
            </LogoutButton>
          </>
        ) : (
          <>
            <StyledLink to="/">Login</StyledLink>
            <StyledLink to="/register">Register</StyledLink>
            <AdminLoginLink to="/admin-login">Admin Login</AdminLoginLink>
          </>
        )}
      </NavLinks>
    </Nav>
  );
}

export default Navigation;