import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import { 
  LineChart, XAxis, YAxis, Tooltip, Legend, Line, 
  BarChart, Bar, RadarChart, Radar, PolarGrid, 
  PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer
} from 'recharts';

// Styled Components
const StatsContainer = styled.div`
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
`;

const Header = styled.div`
  background-color: rgba(154, 127, 93, 0.1);
  padding: 1.5rem;
  border-radius: 8px;
  margin-bottom: 2rem;
`;

const Title = styled.h1`
  color: #BC9C65;
  margin: 0;
  text-align: center;
`;

const SearchBar = styled.div`
  margin: 2rem 0;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
`;

const SearchInput = styled.input`
  padding: 0.8rem;
  border: 1px solid #BC9C65;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  width: 300px;

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }

  &:focus {
    outline: none;
    border-color: #FFD700;
  }
`;

const FilterSection = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin: 1rem 0;
  padding: 1rem;
  background-color: rgba(154, 127, 93, 0.1);
  border-radius: 8px;
  justify-content: center;
`;

const FilterSelect = styled.select`
  padding: 0.5rem;
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid #BC9C65;
  border-radius: 4px;
  color: white;
  min-width: 150px;

  option {
    background-color: #1a1a1a;
  }
`;

const CompareButton = styled.button`
  background-color: #BC9C65;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: #9A7F5D;
  }
`;

const ComparisonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  margin-top: 2rem;
  width: 100%;
`;

const ChartContainer = styled.div`
  background-color: rgba(154, 127, 93, 0.1);
  padding: 1.5rem;
  border-radius: 8px;
  margin-top: 2rem;

  h3 {
    color: #BC9C65;
    margin-bottom: 1rem;
    text-align: center;
  }
`;

const StatBox = styled.div`
  background-color: rgba(154, 127, 93, 0.1);
  padding: 1.5rem;
  border-radius: 8px;
  text-align: center;

  h3 {
    color: #BC9C65;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.5rem;
    color: white;
  }
`;

const DetailSection = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-top: 2rem;
`;

const FighterCard = styled(motion.div)`
  background-color: rgba(154, 127, 93, 0.1);
  padding: 1.5rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: rgba(154, 127, 93, 0.2);
    transform: translateY(-2px);
  }

  h2 {
    color: #BC9C65;
    margin: 0 0 1rem 0;
  }

  p {
    margin: 0.5rem 0;
    color: white;
  }
`;

const TabContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin: 2rem 0;
  justify-content: center;
  flex-wrap: wrap;
`;

const Tab = styled.button`
  background-color: ${props => props.active ? '#BC9C65' : 'transparent'};
  color: ${props => props.active ? 'black' : '#BC9C65'};
  border: 1px solid #BC9C65;
  padding: 0.8rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: ${props => props.active ? '#9A7F5D' : 'rgba(188, 156, 101, 0.1)'};
  }
`;

const BackButton = styled.button`
  background-color: transparent;
  color: #BC9C65;
  border: 1px solid #BC9C65;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
  margin-bottom: 1rem;

  &:hover {
    background-color: rgba(188, 156, 101, 0.1);
  }
`;
function FighterStats() {
  const [fighters, setFighters] = useState([]);
  const [selectedFighter, setSelectedFighter] = useState(null);
  const [comparedFighter, setComparedFighter] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [activeTab, setActiveTab] = useState('overview');
  const [showComparison, setShowComparison] = useState(false);

  const [filters, setFilters] = useState({
    winRate: 'all',
    totalMatches: 'all'
  });

  useEffect(() => {
    const fetchFighters = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + '/api/fighters', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        setFighters(response.data);
      } catch (error) {
        setError('Failed to load fighters data');
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchFighters();
  }, []);

  const calculateStats = useCallback((fighter) => {
    const totalMatches = (fighter.stats?.wins || 0) + 
                        (fighter.stats?.losses || 0) + 
                        (fighter.stats?.draws || 0);

    return {
      winRate: totalMatches ? ((fighter.stats?.wins || 0) / totalMatches * 100).toFixed(1) : 0,
      killRate: totalMatches ? ((fighter.stats?.kills || 0) / totalMatches * 100).toFixed(1) : 0,
      breakRate: totalMatches ? ((fighter.stats?.breaks || 0) / totalMatches * 100).toFixed(1) : 0,
      totalMatches: totalMatches,
    };
  }, []);

  const getPerformanceData = useCallback((fighter) => {
    return [
      { stat: 'Kills', value: fighter.stats?.kills || 0 },
      { stat: 'Breaks', value: fighter.stats?.breaks || 0 },
      { stat: 'Takedowns', value: fighter.stats?.takedowns || 0 },
      { stat: 'Mounts', value: fighter.stats?.mounts || 0 },
      { stat: 'Back Takes', value: fighter.stats?.backtakes || 0 }
    ];
  }, []);

  const getComparisonData = useCallback((fighter1, fighter2) => {
    if (!fighter1 || !fighter2) return [];

    const stats1 = calculateStats(fighter1);
    const stats2 = calculateStats(fighter2);

    return [
      {
        name: 'Wins',
        [fighter1.name]: fighter1.stats?.wins || 0,
        [fighter2.name]: fighter2.stats?.wins || 0
      },
      {
        name: 'Kills',
        [fighter1.name]: fighter1.stats?.kills || 0,
        [fighter2.name]: fighter2.stats?.kills || 0
      },
      {
        name: 'Breaks',
        [fighter1.name]: fighter1.stats?.breaks || 0,
        [fighter2.name]: fighter2.stats?.breaks || 0
      },
      {
        name: 'Takedowns',
        [fighter1.name]: fighter1.stats?.takedowns || 0,
        [fighter2.name]: fighter2.stats?.takedowns || 0
      },
      {
        name: 'Mounts',
        [fighter1.name]: fighter1.stats?.mounts || 0,
        [fighter2.name]: fighter2.stats?.mounts || 0
      }
    ];
  }, [calculateStats]);

  const filteredFighters = useMemo(() => {
    return fighters.filter(fighter => {
      const matchesSearch = fighter.name.toLowerCase().includes(searchTerm.toLowerCase());
      const stats = calculateStats(fighter);
      
      const matchesWinRate = filters.winRate === 'all' ||
        (filters.winRate === 'high' && stats.winRate >= 70) ||
        (filters.winRate === 'medium' && stats.winRate >= 40 && stats.winRate < 70) ||
        (filters.winRate === 'low' && stats.winRate < 40);

      const matchesTotalMatches = filters.totalMatches === 'all' ||
        (filters.totalMatches === 'high' && stats.totalMatches >= 20) ||
        (filters.totalMatches === 'medium' && stats.totalMatches >= 10 && stats.totalMatches < 20) ||
        (filters.totalMatches === 'low' && stats.totalMatches < 10);

      return matchesSearch && matchesWinRate && matchesTotalMatches;
    });
  }, [fighters, searchTerm, filters, calculateStats]);
  return (
    <StatsContainer>
      <Header>
        <Title>Fighter Statistics</Title>
        
        <SearchBar>
          <SearchInput
            placeholder="Search fighters..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </SearchBar>

        <FilterSection>
          <FilterSelect
            value={filters.totalMatches}
            onChange={(e) => setFilters(prev => ({ ...prev, totalMatches: e.target.value }))}
          >
            <option value="all">All Experience</option>
            <option value="high">20+ Matches</option>
            <option value="medium">10-19 Matches</option>
            <option value="low">&lt;10 Matches</option>
          </FilterSelect>

          <FilterSelect
            value={filters.winRate}
            onChange={(e) => setFilters(prev => ({ ...prev, winRate: e.target.value }))}
          >
            <option value="all">All Win Rates</option>
            <option value="high">&gt;70%</option>
            <option value="medium">40-70%</option>
            <option value="low">&lt;40%</option>
          </FilterSelect>
        </FilterSection>
      </Header>

      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>{error}</div>
      ) : (
        <AnimatePresence mode="wait">
          {!selectedFighter ? (
            <motion.div layout>
              {filteredFighters.map((fighter) => {
                const stats = calculateStats(fighter);
                return (
                  <FighterCard
                    key={fighter._id}
                    onClick={() => setSelectedFighter(fighter)}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                  >
                    <h2>{fighter.name}</h2>
                    <p>Record: {fighter.stats?.wins || 0} - {fighter.stats?.losses || 0} - {fighter.stats?.draws || 0}</p>
                    <p>Win Rate: {stats.winRate}%</p>
                    <p>Kills: {fighter.stats?.kills || 0} | Breaks: {fighter.stats?.breaks || 0}</p>
                  </FighterCard>
                );
              })}
            </motion.div>
          ) : (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <BackButton onClick={() => {
                setSelectedFighter(null);
                setComparedFighter(null);
                setShowComparison(false);
              }}>
                ← Back to List
              </BackButton>

              <h2>{selectedFighter.name}</h2>

              {showComparison && (
                <FilterSelect
                  value={comparedFighter?._id || ''}
                  onChange={(e) => setComparedFighter(
                    fighters.find(f => f._id === e.target.value)
                  )}
                >
                  <option value="">Select Fighter to Compare</option>
                  {fighters
                    .filter(f => f._id !== selectedFighter._id)
                    .map(f => (
                      <option key={f._id} value={f._id}>{f.name}</option>
                    ))
                  }
                </FilterSelect>
              )}

              <CompareButton
                onClick={() => setShowComparison(!showComparison)}
              >
                {showComparison ? 'Hide Comparison' : 'Compare Fighter'}
              </CompareButton>

              <TabContainer>
    <Tab 
        active={activeTab === 'overview'}
        onClick={() => setActiveTab('overview')}
    >
        Overview
    </Tab>
    <Tab 
        active={activeTab === 'stats'}
        onClick={() => setActiveTab('stats')}
    >
        Detailed Stats
    </Tab>
    {showComparison && (
        <Tab 
            active={activeTab === 'comparison'}
            onClick={() => setActiveTab('comparison')}
        >
            Comparison
        </Tab>
    )}
</TabContainer>

              {activeTab === 'overview' && (
                <DetailSection>
                  <StatBox>
                    <h3>Record</h3>
                    <p>{selectedFighter.stats?.wins || 0} - {selectedFighter.stats?.losses || 0} - {selectedFighter.stats?.draws || 0}</p>
                  </StatBox>
                  <StatBox>
                    <h3>Win Rate</h3>
                    <p>{calculateStats(selectedFighter).winRate}%</p>
                  </StatBox>
                  <StatBox>
                    <h3>Kill Rate</h3>
                    <p>{calculateStats(selectedFighter).killRate}%</p>
                  </StatBox>
                  <StatBox>
                    <h3>Break Rate</h3>
                    <p>{calculateStats(selectedFighter).breakRate}%</p>
                  </StatBox>
                </DetailSection>
              )}

              {activeTab === 'stats' && (
                <ChartContainer>
                  <h3>Performance Breakdown</h3>
                  <ResponsiveContainer width="100%" height={400}>
                    <RadarChart data={getPerformanceData(selectedFighter)}>
                      <PolarGrid />
                      <PolarAngleAxis dataKey="stat" />
                      <PolarRadiusAxis />
                      <Radar 
                        name={selectedFighter.name} 
                        dataKey="value" 
                        stroke="#BC9C65" 
                        fill="#BC9C65" 
                        fillOpacity={0.6} 
                      />
                    </RadarChart>
                  </ResponsiveContainer>

                  <DetailSection>
                    <StatBox>
                      <h3>Takedowns</h3>
                      <p>{selectedFighter.stats?.takedowns || 0}</p>
                    </StatBox>
                    <StatBox>
                      <h3>Mounts</h3>
                      <p>{selectedFighter.stats?.mounts || 0}</p>
                    </StatBox>
                    <StatBox>
                      <h3>Back Takes</h3>
                      <p>{selectedFighter.stats?.backtakes || 0}</p>
                    </StatBox>
                  </DetailSection>
                </ChartContainer>
              )}

              {activeTab === 'comparison' && showComparison && comparedFighter && (
                <ComparisonContainer>
                  <ChartContainer>
                    <h3>Stats Comparison</h3>
                    <ResponsiveContainer width="100%" height={400}>
                      <BarChart data={getComparisonData(selectedFighter, comparedFighter)}>
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey={selectedFighter.name} fill="#BC9C65" />
                        <Bar dataKey={comparedFighter.name} fill="#9A7F5D" />
                      </BarChart>
                    </ResponsiveContainer>
                  </ChartContainer>

                  <ChartContainer>
                    <h3>Performance Comparison</h3>
                    <ResponsiveContainer width="100%" height={400}>
                      <RadarChart data={getPerformanceData(selectedFighter)}>
                        <PolarGrid />
                        <PolarAngleAxis dataKey="stat" />
                        <PolarRadiusAxis />
                        <Radar 
                          name={selectedFighter.name} 
                          dataKey="value" 
                          stroke="#BC9C65" 
                          fill="#BC9C65" 
                          fillOpacity={0.6} 
                        />
                        <Radar 
                          name={comparedFighter.name} 
                          dataKey="value" 
                          stroke="#9A7F5D" 
                          fill="#9A7F5D" 
                          fillOpacity={0.6} 
                          data={getPerformanceData(comparedFighter)} 
                        />
                      </RadarChart>
                    </ResponsiveContainer>
                  </ChartContainer>
                </ComparisonContainer>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </StatsContainer>
  );
}

export default FighterStats;