import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const Header = styled.div`
  background-color: rgba(154, 127, 93, 0.1);
  padding: 2rem;
  border-radius: 8px;
  margin-bottom: 2rem;
  text-align: center;
`;

const Logo = styled.img`
  max-width: 400px;
  height: auto;
  margin-bottom: 1rem;
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.3));
`;

const HeaderText = styled.h1`
  color: #BC9C65;
  margin: 1rem 0;
  font-size: 2.5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  display: none; // Hidden when logo is present
`;

const FilterBar = styled.div`
  display: flex;
  gap: 1rem;
  margin: 2rem 0;
  padding: 1rem;
  background-color: rgba(154, 127, 93, 0.1);
  border-radius: 8px;
  flex-wrap: wrap;
  justify-content: center;
`;

const FilterButton = styled.button.attrs(props => ({
  'data-active': props.active // Convert boolean to string attribute
}))`
  background-color: ${props => props.active ? '#BC9C65' : 'transparent'};
  color: ${props => props.active ? 'black' : '#BC9C65'};
  border: 1px solid #BC9C65;
  padding: 0.8rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
  font-weight: ${props => props.active ? 'bold' : 'normal'};

  &:hover {
    background-color: ${props => props.active ? '#9A7F5D' : 'rgba(188, 156, 101, 0.1)'};
    transform: translateY(-2px);
  }
`;

const PostGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const PostCard = styled.div`
  background-color: rgba(154, 127, 93, 0.1);
  padding: 2rem;
  border-radius: 8px;
  transition: transform 0.2s;
  border: 1px solid rgba(188, 156, 101, 0.2);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

const PostHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(154, 127, 93, 0.3);
`;

const PostTitle = styled.h2`
  color: #BC9C65;
  margin: 0;
  font-size: 1.8rem;
`;

const PostMeta = styled.div`
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.9rem;
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const PostType = styled.span`
  background-color: rgba(188, 156, 101, 0.2);
  padding: 0.3rem 0.8rem;
  border-radius: 4px;
  font-size: 0.8rem;
  color: #BC9C65;
`;

const PostContent = styled.div`
  color: white;
  line-height: 1.8;
  white-space: pre-wrap;
  font-size: 1.1rem;
`;

const MatchesSection = styled.div`
  margin-top: 2rem;
  padding-top: 1.5rem;
  border-top: 1px solid rgba(154, 127, 93, 0.3);
`;

const MatchList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin-top: 1rem;
`;

const MatchItem = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  padding: 1rem;
  border-radius: 4px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;


function FantasyCorner() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [filter, setFilter] = useState('all');

  useEffect(() => {
    const loadPosts = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + '/api/posts', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        setPosts(response.data);
      } catch (err) {
        setError('Failed to load posts');
        console.error('Error loading posts:', err);
      } finally {
        setLoading(false);
      }
    };

    loadPosts();
  }, []);

  const filteredPosts = posts.filter(post => {
    if (filter === 'all') return true;
    return post.type === filter;
  });

  return (
    <Container>
      <Header>
        <Logo 
          src="/fantasycorner.png" 
          alt="Fantasy Corner Grappling"
          onError={(e) => {
            e.target.style.display = 'none';
            document.querySelector('#headerText').style.display = 'block';
          }}
        />
        <HeaderText id="headerText">Fantasy Corner</HeaderText>
      </Header>

      <FilterBar>
        <FilterButton 
          active={filter === 'all'}
          onClick={() => setFilter('all')}
        >
          All Posts
        </FilterButton>
        <FilterButton 
          active={filter === 'fight_report'}
          onClick={() => setFilter('fight_report')}
        >
          Fight Reports
        </FilterButton>
        <FilterButton 
          active={filter === 'daily_writeup'}
          onClick={() => setFilter('daily_writeup')}
        >
          Daily Writeups
        </FilterButton>
        <FilterButton 
          active={filter === 'news'}
          onClick={() => setFilter('news')}
        >
          News
        </FilterButton>
      </FilterBar>

      {loading ? (
        <div style={{ textAlign: 'center', color: '#BC9C65', padding: '2rem' }}>
          Loading...
        </div>
      ) : error ? (
        <div style={{ textAlign: 'center', color: '#ff6b6b', padding: '2rem' }}>
          {error}
        </div>
      ) : (
        <PostGrid>
          {filteredPosts.map(post => (
            <PostCard key={post._id}>
              <PostHeader>
                <PostTitle>{post.title}</PostTitle>
                <PostMeta>
                  <PostType>
                    {post.type.replace('_', ' ').toUpperCase()}
                  </PostType>
                  By {post.author?.username || 'Admin'} • {new Date(post.createdAt).toLocaleDateString()}
                </PostMeta>
              </PostHeader>
              <PostContent>{post.content}</PostContent>
              {post.type === 'fight_report' && post.relatedMatches?.length > 0 && (
                <MatchesSection>
                  <h3>Related Matches</h3>
                  <MatchList>
                    {post.relatedMatches.map(match => (
                      <MatchItem key={match._id}>
                        {match.winner} vs {match.loser} - {match.method}
                      </MatchItem>
                    ))}
                  </MatchList>
                </MatchesSection>
              )}
            </PostCard>
          ))}
          {filteredPosts.length === 0 && (
            <div style={{ textAlign: 'center', color: '#BC9C65', padding: '2rem' }}>
              No posts found in this category
            </div>
          )}
        </PostGrid>
      )}
    </Container>
  );
}

export default FantasyCorner;