import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import FighterCard from './FighterCard';
import { motion, AnimatePresence } from 'framer-motion';

const FighterListContainer = styled.div`
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
`;

const ControlsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 2rem;
  align-items: center;
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const SearchBar = styled.input`
  width: 100%;
  max-width: 300px;
  padding: 0.8rem;
  background-color: rgba(154, 127, 93, 0.1);
  border: 1px solid #BC9C65;
  border-radius: 4px;
  color: white;
  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`;

const Select = styled.select`
  padding: 0.8rem;
  background-color: rgba(154, 127, 93, 0.1);
  border: 1px solid #BC9C65;
  border-radius: 4px;
  color: white;
  cursor: pointer;

  option {
    background-color: #1a1a1a;
  }
`;

const FighterGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  justify-items: center;
`;

const Title = styled.h2`
  color: #BC9C65;
  margin-bottom: 2rem;
  text-align: center;
`;

const FilterButton = styled.button`
  background-color: ${props => props.active ? '#BC9C65' : 'transparent'};
  color: ${props => props.active ? 'black' : '#BC9C65'};
  border: 1px solid #BC9C65;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: ${props => props.active ? '#9A7F5D' : 'rgba(188, 156, 101, 0.1)'};
  }
`;

const StatsOverview = styled.div`
  background-color: rgba(154, 127, 93, 0.1);
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
`;

const StatItem = styled.div`
  text-align: center;
  color: white;

  span {
    color: #BC9C65;
    font-weight: bold;
  }
`;

const NoResults = styled.div`
  text-align: center;
  color: #BC9C65;
  padding: 2rem;
  grid-column: 1 / -1;
`;

function FighterList() {
  const [fighters, setFighters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('name');
  const [filterBy, setFilterBy] = useState('all');
  const [user, setUser] = useState(null);
  const [stats, setStats] = useState({
    totalFighters: 0,
    averageWins: 0,
    totalKills: 0,
    highestBreakPercentage: 0
  });

  useEffect(() => {
    const loadData = async () => {
      try {
        const [fightersResponse, userResponse] = await Promise.all([
          axios.get(process.env.REACT_APP_API_URL + '/api/fighters', {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
          }),
          axios.get(process.env.REACT_APP_API_URL + '/api/users/profile', {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
          })
        ]);
        
        const fightersData = fightersResponse.data;
        setFighters(fightersData);
        setUser(userResponse.data);
        
        // Calculate stats
        const totalFighters = fightersData.length;
        const totalWins = fightersData.reduce((sum, f) => sum + (f.stats?.wins || 0), 0);
        const totalKills = fightersData.reduce((sum, f) => sum + (f.stats?.kills || 0), 0);
        const highestBreakPercentage = Math.max(
          ...fightersData.map(f => f.stats?.breakPercentage || 0)
        );

        setStats({
          totalFighters,
          averageWins: totalFighters ? (totalWins / totalFighters).toFixed(1) : 0,
          totalKills,
          highestBreakPercentage
        });

      } catch (error) {
        console.error('Failed to load fighters:', error);
        setError('Failed to load fighters');
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  const handleDeleteFighter = (fighterId) => {
    setFighters(fighters.filter(f => f._id !== fighterId));
  };

  const sortFighters = (fightersToSort) => {
    return [...fightersToSort].sort((a, b) => {
      switch (sortBy) {
        case 'name':
          return a.name.localeCompare(b.name);
        case 'price':
          return b.price - a.price;
        case 'wins':
          return (b.stats?.wins || 0) - (a.stats?.wins || 0);
        case 'kills':
          return (b.stats?.kills || 0) - (a.stats?.kills || 0);
        case 'breaks':
          return (b.stats?.breaks || 0) - (a.stats?.breaks || 0);
        case 'points':
          return (b.stats?.elbowGeniePoints || 0) - (a.stats?.elbowGeniePoints || 0);
        default:
          return 0;
      }
    });
  };

  const filterFighters = (fightersToFilter) => {
    return fightersToFilter.filter(fighter => {
      const matchesSearch = fighter.name.toLowerCase().includes(searchTerm.toLowerCase());
      
      switch (filterBy) {
        case 'available':
          return matchesSearch && fighter.available;
        case 'highKills':
          return matchesSearch && (fighter.stats?.kills || 0) > 2;
        case 'highBreaks':
          return matchesSearch && (fighter.stats?.breaks || 0) > 2;
        default:
          return matchesSearch;
      }
    });
  };

  const filteredAndSortedFighters = sortFighters(filterFighters(fighters));

  if (loading) return <FighterListContainer>Loading...</FighterListContainer>;
  if (error) return <FighterListContainer>{error}</FighterListContainer>;

  return (
    <FighterListContainer>
      <Title>Fighter Database</Title>

      <StatsOverview>
        <StatItem>
          <div>Total Fighters</div>
          <span>{stats.totalFighters}</span>
        </StatItem>
        <StatItem>
          <div>Average Wins</div>
          <span>{stats.averageWins}</span>
        </StatItem>
        <StatItem>
          <div>Total Kills</div>
          <span>{stats.totalKills}</span>
        </StatItem>
        <StatItem>
          <div>Highest Break %</div>
          <span>{stats.highestBreakPercentage}%</span>
        </StatItem>
      </StatsOverview>

      <ControlsContainer>
        <SearchBar
          type="text"
          placeholder="Search fighters..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <Select value={sortBy} onChange={(e) => setSortBy(e.target.value)}>
          <option value="name">Sort by Name</option>
          <option value="price">Sort by Price</option>
          <option value="wins">Sort by Wins</option>
          <option value="kills">Sort by Kills</option>
          <option value="breaks">Sort by Breaks</option>
          <option value="points">Sort by Points</option>
        </Select>

        <div>
          <FilterButton 
            active={filterBy === 'all'} 
            onClick={() => setFilterBy('all')}
          >
            All
          </FilterButton>
          <FilterButton 
            active={filterBy === 'available'} 
            onClick={() => setFilterBy('available')}
          >
            Available
          </FilterButton>
          <FilterButton 
            active={filterBy === 'highKills'} 
            onClick={() => setFilterBy('highKills')}
          >
            High Kills
          </FilterButton>
          <FilterButton 
            active={filterBy === 'highBreaks'} 
            onClick={() => setFilterBy('highBreaks')}
          >
            High Breaks
          </FilterButton>
        </div>
      </ControlsContainer>

      <AnimatePresence>
        <FighterGrid>
          {filteredAndSortedFighters.length > 0 ? (
            filteredAndSortedFighters.map((fighter) => (
              <motion.div
                key={fighter._id}
                layout
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.9 }}
                transition={{ duration: 0.2 }}
              >
                <FighterCard
                  fighter={fighter}
                  isAdmin={user?.role === 'admin'}
                  onDelete={handleDeleteFighter}
                />
              </motion.div>
            ))
          ) : (
            <NoResults>No fighters found matching your criteria</NoResults>
          )}
        </FighterGrid>
      </AnimatePresence>
    </FighterListContainer>
  );
}

export default FighterList;