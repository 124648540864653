import React from 'react';
import styled from 'styled-components';

const PageContainer = styled.div`
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 3rem;
`;

const Title = styled.h1`
  color: #BC9C65;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

const Subtitle = styled.p`
  color: #9A7F5D;
  font-size: 1.2rem;
`;

const PrizeSection = styled.div`
  background-color: rgba(154, 127, 93, 0.1);
  border-radius: 8px;
  padding: 2rem;
  margin-bottom: 2rem;
`;

const PrizeTitle = styled.h2`
  color: #BC9C65;
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  text-align: center;
`;

const PrizeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
`;

const PrizeCard = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  border: 1px solid #9A7F5D;
  border-radius: 8px;
  padding: 1.5rem;
  text-align: center;
`;

const PrizeRank = styled.div`
  font-size: 2rem;
  color: #BC9C65;
  margin-bottom: 1rem;
`;

const PrizeList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  color: white;

  li {
    margin-bottom: 0.5rem;
    &:before {
      content: '•';
      color: #BC9C65;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }
`;

const Message = styled.p`
  color: #BC9C65;
  font-size: 1.2rem;
  text-align: center;
  margin-top: 3rem;

  a {
    color: #FFD700;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

function PrizesPage() {
  return (
    <PageContainer>
      <Header>
        <Title>Tournament Prizes</Title>
        <Subtitle>Compete for exclusive PGF rewards</Subtitle>
      </Header>

      <PrizeSection>
        <PrizeTitle>Season Prizes</PrizeTitle>
        <PrizeGrid>
          <PrizeCard>
            <PrizeRank>🥇 1st Place</PrizeRank>
            <PrizeList>
              <li>Exclusive PGF Championship Belt</li>
              <li>Signed Gi by Legendary PGF Coaches</li>
              <li>Special Prize from Intrana</li>
            </PrizeList>
          </PrizeCard>

          <PrizeCard>
            <PrizeRank>🥈 2nd Place</PrizeRank>
            <PrizeList>
              <li>Throwback PGF Rashguard Signed by PGF Coaches and Partners</li>
              <li>Special Prize from Intrana</li>
    
            </PrizeList>
          </PrizeCard>

          <PrizeCard>
            <PrizeRank>🥉 3rd Place</PrizeRank>
            <PrizeList>
              <li>100 Burpees</li>
               <li>Special Prize from Intrana</li>
          
            </PrizeList>
          </PrizeCard>
        </PrizeGrid>
      </PrizeSection>

      <PrizeSection>
        <PrizeTitle>Daily Prize</PrizeTitle>
        <PrizeCard>
          <PrizeList>
            <li>PGF Rashguard</li>
            <li>Signed Season 7 Poster</li>
            <li>Awarded to the highest scoring competitor each day</li>
          </PrizeList>
        </PrizeCard>
      </PrizeSection>

    
    </PageContainer>
  );
}

export default PrizesPage;
