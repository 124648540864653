import React from 'react';
import styled from 'styled-components';

const LeaderboardContainer = styled.div`
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
`;

const Header = styled.div`
  background-color: rgba(154, 127, 93, 0.1);
  padding: 1.5rem;
  border-radius: 8px;
  margin-bottom: 2rem;
  border-bottom: 1px solid #9A7F5D;
  text-align: center;
`;

const HeaderTitle = styled.h1`
  color: #BC9C65;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 0;
  font-size: 2.5rem;
`;

const StatsOverview = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
`;

const StatCard = styled.div`
  background-color: rgba(154, 127, 93, 0.1);
  padding: 1.5rem;
  border-radius: 8px;
  text-align: center;

  h3 {
    color: #BC9C65;
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  p {
    font-size: 2rem;
    color: white;
    margin: 0;
  }
`;

const RankingsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const RankingCard = styled.div`
  background-color: rgba(154, 127, 93, 0.1);
  padding: 1.5rem;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: transform 0.2s;

  &:hover {
    transform: translateY(-2px);
  }
`;

const RankInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;

const RankNumber = styled.span`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${props => props.$rank === 1 ? '#FFD700' : '#BC9C65'};
  min-width: 40px;
  text-align: center;
`;

const UserInfo = styled.div`
  h2 {
    color: #BC9C65;
    margin: 0 0 0.25rem 0;
    font-size: 1.2rem;
  }

  p {
    color: rgba(255, 255, 255, 0.6);
    margin: 0;
    font-size: 0.9rem;
  }
`;

const ScoreInfo = styled.div`
  text-align: right;

  .total-score {
    font-size: 1.5rem;
    font-weight: bold;
    color: ${props => props.$rank === 1 ? '#FFD700' : 'white'};
    margin: 0;
  }

  .stats {
    color: #BC9C65;
    font-size: 0.9rem;
    margin: 0;
  }
`;

const StaticLeaderboard = () => {
    const rankings = [
    { rank: 1, username: "Neckstepgrappling", teamName: "Neckstepgrappling", totalPoints: 488.00, days: 4, avgPoints: 122.00, lastScore: 135.00 },
    { rank: 2, username: "Gebhartd1", teamName: "Cringey white belt", totalPoints: 471.00, days: 4, avgPoints: 117.75, lastScore: 135.00 },
    { rank: 3, username: "BigDaddy", teamName: "Big Daddy", totalPoints: 468.00, days: 4, avgPoints: 117.00, lastScore: 178.00 },
    { rank: 4, username: "CalebSpellman", teamName: "Day 2 20pts", totalPoints: 466.00, days: 3, avgPoints: 155.33, lastScore: 153.00 },
    { rank: 5, username: "MonkeyMan", teamName: "MonkeyMan", totalPoints: 450.00, days: 4, avgPoints: 112.50, lastScore: 160.00 },
    { rank: 6, username: "Mando", teamName: "Mandersonnn", totalPoints: 443.00, days: 3, avgPoints: 147.67, lastScore: 160.00 },
    { rank: 7, username: "AnnieB", teamName: "ShouldHaveUsedFantisizer", totalPoints: 442.00, days: 4, avgPoints: 110.50, lastScore: 148.00 },
    { rank: 8, username: "Nathan Lucas", teamName: "New Hope Killers", totalPoints: 434.00, days: 3, avgPoints: 144.67, lastScore: 167.00 },
    { rank: 9, username: "Clarkbarlowe10p", teamName: "Hinduorbust", totalPoints: 434.00, days: 3, avgPoints: 144.67, lastScore: 171.00 },
    { rank: 10, username: "Neilbjj", teamName: "Celtic Stranglers", totalPoints: 433.00, days: 3, avgPoints: 144.33, lastScore: 113.00 },
    { rank: 11, username: "MamaGreenThumbelina", teamName: "Tailgaters", totalPoints: 394.00, days: 3, avgPoints: 131.33, lastScore: 128.00 },
    { rank: 12, username: "Jholmes", teamName: "Thedawgs", totalPoints: 371.00, days: 3, avgPoints: 123.67, lastScore: 104.00 },
    { rank: 13, username: "john_medbery@me.com", teamName: "BerimbolHoe", totalPoints: 370.00, days: 3, avgPoints: 123.33, lastScore: 145.00 },
    { rank: 14, username: "JettsDad", teamName: "Jett's Dad", totalPoints: 361.00, days: 3, avgPoints: 120.33, lastScore: 92.00 },
    { rank: 15, username: "MsSpell", teamName: "madpointz", totalPoints: 328.00, days: 2, avgPoints: 164.00, lastScore: 178.00 },
    { rank: 16, username: "Bean", teamName: "Bean's Badasses", totalPoints: 327.00, days: 4, avgPoints: 81.75, lastScore: 72.00 },
    { rank: 17, username: "PAC_Jace", teamName: "ReelRooks in da Gi", totalPoints: 301.00, days: 3, avgPoints: 100.33, lastScore: 72.00 },
    { rank: 18, username: "Lamb4bama", teamName: "Lamb4bama", totalPoints: 300.00, days: 2, avgPoints: 150.00, lastScore: 172.00 },
    { rank: 19, username: "Bsqair", teamName: "Bama Bsqair", totalPoints: 298.00, days: 2, avgPoints: 149.00, lastScore: 172.00 },
    { rank: 20, username: "BearJ", teamName: "BearJew-Jitsu", totalPoints: 290.00, days: 2, avgPoints: 145.00, lastScore: 140.00 },
    { rank: 21, username: "Drewjitsu10p", teamName: "NoWeakShit", totalPoints: 289.00, days: 2, avgPoints: 144.50, lastScore: 145.00 },
    { rank: 22, username: "@bostonanik", teamName: "ANIK", totalPoints: 289.00, days: 2, avgPoints: 144.50, lastScore: 117.00 },
    { rank: 23, username: "AllenMichael", teamName: "Mr. Miya(no)gi", totalPoints: 287.00, days: 3, avgPoints: 95.67, lastScore: 110.00 },
    { rank: 24, username: "KaneKong", teamName: "The Seven", totalPoints: 278.00, days: 2, avgPoints: 139.00, lastScore: 143.00 },
    { rank: 25, username: "JacksonSpry", teamName: "Spry's Season 5 Revenge", totalPoints: 275.00, days: 2, avgPoints: 137.50, lastScore: 142.00 },
    { rank: 26, username: "Bmac Bruisers", teamName: "Bmac's Bruisers", totalPoints: 266.00, days: 2, avgPoints: 133.00, lastScore: 160.00 },
    { rank: 27, username: "izzywheat", teamName: "izzobot", totalPoints: 264.00, days: 2, avgPoints: 132.00, lastScore: 127.00 },
    { rank: 28, username: "ThoughtfulMovement", teamName: "Thoughtful Movement", totalPoints: 261.00, days: 3, avgPoints: 87.00, lastScore: 85.00 },
    { rank: 29, username: "Jayfitzy", teamName: "Rizzlers", totalPoints: 258.00, days: 2, avgPoints: 129.00, lastScore: 135.00 },
    { rank: 30, username: "Atlantasubmissionseries", teamName: "Rank 1", totalPoints: 255.00, days: 2, avgPoints: 127.50, lastScore: 130.00 },
    { rank: 31, username: "CoryTBJJ", teamName: "Gifssme achokin papa", totalPoints: 247.00, days: 2, avgPoints: 123.50, lastScore: 146.00 },
    { rank: 32, username: "Ginganinjacjj170", teamName: "Ginga Ninja", totalPoints: 245.00, days: 2, avgPoints: 122.50, lastScore: 147.00 },
    { rank: 33, username: "jiujitsu guy", teamName: "winning team", totalPoints: 240.00, days: 3, avgPoints: 80.00, lastScore: 3.00 },
    { rank: 34, username: "Amy Lucas", teamName: "NewHopeNurse", totalPoints: 238.00, days: 2, avgPoints: 119.00, lastScore: 178.00 },
    { rank: 35, username: "Gibby", teamName: "HereWeGoAgain", totalPoints: 221.00, days: 2, avgPoints: 110.50, lastScore: 149.00 },
    { rank: 36, username: "Brice.G.Roads", teamName: "Grappling Roads", totalPoints: 210.00, days: 2, avgPoints: 105.00, lastScore: 88.00 },
    { rank: 37, username: "EggheadWarrior", teamName: "Egghead's Warriors", totalPoints: 207.00, days: 2, avgPoints: 103.50, lastScore: 108.00 },
    { rank: 38, username: "Bobshtoned", teamName: "Zyn it to win it", totalPoints: 200.00, days: 2, avgPoints: 100.00, lastScore: 106.00 },
    { rank: 39, username: "bmac", teamName: "Team Bmac", totalPoints: 194.00, days: 3, avgPoints: 64.67, lastScore: 60.00 },
    { rank: 40, username: "HUHAISSIE", teamName: "The Marihuas", totalPoints: 191.00, days: 2, avgPoints: 95.50, lastScore: 108.00 },
    { rank: 41, username: "intrana", teamName: "INT", totalPoints: 191.00, days: 2, avgPoints: 95.50, lastScore: 99.00 },
    { rank: 42, username: "Derinbolo", teamName: "Wbolo", totalPoints: 178.00, days: 1, avgPoints: 178.00, lastScore: 178.00 },
    { rank: 43, username: "Trashandjiujitsu", teamName: "T&J", totalPoints: 178.00, days: 2, avgPoints: 89.00, lastScore: 111.00 },
    { rank: 44, username: "nortongene", teamName: "NoMrUtah", totalPoints: 175.00, days: 2, avgPoints: 87.50, lastScore: 106.00 },
    { rank: 45, username: "Trustnu", teamName: "Team made", totalPoints: 172.00, days: 1, avgPoints: 172.00, lastScore: 172.00 },
    { rank: 46, username: "Dmurr", totalPoints: 167.00, days: 1, avgPoints: 167.00, lastScore: 167.00 },
    { rank: 47, username: "BooneJitsu", teamName: "BooneJitsu", totalPoints: 159.00, days: 1, avgPoints: 159.00, lastScore: 159.00 },
    { rank: 48, username: "preet10p", teamName: "10p Sacramento", totalPoints: 154.00, days: 1, avgPoints: 154.00, lastScore: 154.00 },
    { rank: 49, username: "aenriquez218", totalPoints: 152.00, days: 1, avgPoints: 152.00, lastScore: 152.00 },
    { rank: 50, username: "testuser", teamName: "GRAPPLING GURU", totalPoints: 576.00, days: 9, avgPoints: 64.00, lastScore: 107.00 }
];
  return (
    <LeaderboardContainer>
      <Header>
        <HeaderTitle>Tournament Rankings</HeaderTitle>
      </Header>

      <StatsOverview>
        <StatCard>
          <h3>Total Participants</h3>
          <p>272</p>
        </StatCard>
        <StatCard>
          <h3>Highest Score</h3>
          <p>488.00</p>
        </StatCard>
        <StatCard>
          <h3>Average Score</h3>
          <p>82.10</p>
        </StatCard>
        <StatCard>
          <h3>Highest Daily</h3>
          <p>136.00</p>
        </StatCard>
      </StatsOverview>

      <RankingsList>
        {rankings.map(rank => (
          <RankingCard key={rank.rank}>
            <RankInfo>
              <RankNumber $rank={rank.rank}>
                #{rank.rank}
              </RankNumber>
              <UserInfo>
                <h2>{rank.username}</h2>
                <p>{rank.teamName}</p>
              </UserInfo>
            </RankInfo>
            <ScoreInfo $rank={rank.rank}>
              <p className="total-score">{rank.totalPoints.toFixed(2)}</p>
              <p className="stats">
                {rank.days} Days • Avg: {rank.avgPoints.toFixed(2)} • Last: {rank.lastScore.toFixed(2)}
              </p>
            </ScoreInfo>
          </RankingCard>
        ))}
      </RankingsList>
    </LeaderboardContainer>
  );
};

export default StaticLeaderboard;
