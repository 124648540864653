import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';

const AdminLoginContainer = styled.div`
  max-width: 400px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: rgba(154, 127, 93, 0.1);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  color: #BC9C65;
  text-align: center;
  margin-bottom: 2rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Input = styled.input`
  padding: 0.8rem;
  border: 1px solid #9A7F5D;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  
  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }

  &:focus {
    outline: none;
    border-color: #BC9C65;
    box-shadow: 0 0 0 2px rgba(188, 156, 101, 0.2);
  }
`;

const Button = styled.button`
  background-color: #BC9C65;
  color: white;
  border: none;
  padding: 1rem;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #9A7F5D;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.div`
  color: #ff6b6b;
  background-color: rgba(255, 107, 107, 0.1);
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  text-align: center;
`;


function AdminLogin() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setError('');
      setLoading(true);
  
      try {
        // First attempt login
        const response = await axios.post(process.env.REACT_APP_API_URL + '/api/auth/login', {
          email,
          password
        });
  
        // Check if user is admin
        const { token } = response.data;
        const userResponse = await axios.get(process.env.REACT_APP_API_URL + '/api/users/profile', {
          headers: { Authorization: `Bearer ${token}` }
        });
  
        if (userResponse.data.role !== 'admin') {
          setError('Access denied. Admin privileges required.');
          localStorage.removeItem('token');
          setLoading(false);
          return;
        }
  
        // Successfully logged in as admin
        localStorage.setItem('token', token);
        localStorage.setItem('adminToken', token); // Optional: separate admin token
        navigate('/admin');
        
      } catch (error) {
        console.error('Admin login error:', error);
        setError(error.response?.data?.message || 'Invalid credentials');
        localStorage.removeItem('token');
      } finally {
        setLoading(false);
      }
    };
  
    return (
      <AdminLoginContainer>
        <Title>Admin Login</Title>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <Form onSubmit={handleSubmit}>
          <Input
            type="email"
            placeholder="Admin Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            disabled={loading}
          />
          <Input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            disabled={loading}
          />
          <Button type="submit" disabled={loading}>
            {loading ? 'Logging in...' : 'Login as Admin'}
          </Button>
        </Form>
      </AdminLoginContainer>
    );
  }
  
  export default AdminLogin;